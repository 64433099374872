<template>
  <div class="content">
    <Sidebar />
    <div class="master-sheet">
      <div class="title">
        <p>Master Sheet</p>
      </div>
      <div class="controls">
        <div class="controls-item" @click="toggleFilter()" v-if="filterOn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-eye"
          >
            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
            <circle cx="12" cy="12" r="3"></circle>
          </svg>
        </div>
        <div class="controls-item" @click="toggleFilter()" v-else>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-eye-off"
          >
            <path
              d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"
            ></path>
            <line x1="1" y1="1" x2="23" y2="23"></line>
          </svg>
        </div>
        <div class="controls-item" @click="downloadCSV('master')">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-download"
          >
            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
            <polyline points="7 10 12 15 17 10"></polyline>
            <line x1="12" y1="15" x2="12" y2="3"></line>
          </svg>
        </div>
        <div class="filters">
          <div class="filter">
            <div id="title">Start Date</div>
            <input type="date" v-model="start_date" />
          </div>
          <div class="filter">
            <div id="title">End Date</div>
            <input type="date" v-model="end_date" />
          </div>
        </div>
      </div>
      <div class="table-wrap">
        <table id="master">
          <tr>
            <th>Date</th>
            <th>Company Name</th>
            <th>Type</th>
            <th>Payment Posted</th>
            <th>Pick Up</th>
            <th>Delivery</th>
            <th>Empty Miles</th>
            <th>Loaded Miles</th>
            <th>Empty Miles Rate</th>
            <th>Load Price</th>
            <th>Driver's Rate</th>
            <th>Empty Miles Payment</th>
            <th>Loaded Miles Payment</th>
            <th>Total Driver Payment</th>
            <th>Net Income</th>
            <th>Driver Paid</th>
          </tr>
          <TripRow
            v-for="(item, index) in filteredTrips"
            :key="index"
            :card_index="index"
            :doc_id="item.id"
            :trip_type="item.trip_type"
            :trip_date="item.trip_date"
            :pick_up_location="item.pick_up_location"
            :delivery_location="item.delivery_location"
            :start_location="item.start_location"
            :empty_miles="item.empty_miles"
            :loaded_miles="item.loaded_miles"
            :total_payment="item.total_payment"
            :company_name="item.company_name"
            :payment_posted="item.payment_posted"
            :gross_income="item.gross_income"
            :empty_miles_rate="item.empty_miles_rate"
            :driver_rate="item.driver_rate"
            :loaded_miles_payment="item.loaded_miles_payment"
            :empty_miles_payment="item.empty_miles_payment"
            :fuel_expenses="item.fuel_expenses"
            :insurance_cost="item.insurance_cost"
            :net_income_after_fuel="item.net_income_after_fuel"
            :net_income="item.net_income"
            :driver_paid="item.driver_paid"
            :filterOn="filterOn"
          />
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from "@/components/sidebar.vue";
import Dashboard from "@/components/dashboard.vue";
import TripRow from "@/components/trip-row.vue";

export default {
  name: "MasterView",
  components: { Sidebar, Dashboard, TripRow },
  data() {
    return {
      field1: null,
      filterOn: true,
      start_date: new Date("2020-01-01"),
      end_date: new Date().toJSON().slice(0, 10).replace(/-/g, "-"),
    };
  },

  methods: {
    toggleFilter() {
      this.filterOn = !this.filterOn;
    },
    downloadCSV(table_id, separator = ",") {
      //shamelessly stolen from https://stackoverflow.com/questions/15547198/export-html-table-to-csv-using-vanilla-javascript
      // Select rows from table_id
      var rows = document.querySelectorAll("table#" + table_id + " tr");
      // Construct csv
      var csv = [];
      for (var i = 0; i < rows.length; i++) {
        var row = [],
          cols = rows[i].querySelectorAll("td, th");
        for (var j = 0; j < cols.length; j++) {
          var data = cols[j].innerText
            .replace(/(\r\n|\n|\r)/gm, "")
            .replace(/(\s\s)/gm, " ");

          data = data.replace(/"/g, '""');

          row.push('"' + data + '"');
        }
        csv.push(row.join(separator));
      }
      var csv_string = csv.join("\n");

      var filename =
        "BNFT_Master" + "_" + new Date().toLocaleDateString() + ".csv";
      var link = document.createElement("a");
      link.style.display = "none";
      link.setAttribute("target", "_blank");
      link.setAttribute(
        "href",
        "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
      );
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  computed: {
    filteredTrips: function () {
      let trips_between_dates = this.$store.state.trips.filter(
        (item) =>
          new Date(item.trip_date.toDate()) > new Date(this.start_date) &&
          new Date(item.trip_date.toDate()) < new Date(this.end_date)
      );

      console.log(trips_between_dates);
      return trips_between_dates;
    },
  },
};
</script>

<style scoped>
.content {
  margin-top: 2rem;
  width: 99%;
  max-width: 1800px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  gap: 10px;
}
.master-sheet {
  flex: 2;
  background-color: var(--projects-section);
  border-radius: 32px;
  padding: 32px 32px;
  width: 95%;
  height: 80vh;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.title p {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  opacity: 0.9;
  margin: 0;
  color: var(--main-color);
}

.controls {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  margin: 10px 0;
}
.controls-item {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  border-radius: 4px;
  background-color: transparent;
  border: none;
  color: var(--main-color);
  margin-left: 8px;
  transition: 0.2s;
}

.controls-item:hover {
  background-color: var(--shadow);
}

.table-wrap {
  width: 100%;

  overflow: scroll;
}

table {
  width: 100%;
  margin: 20px auto;
  table-layout: auto;
}
table,
td,
th {
  border-collapse: collapse;
}
th,
td {
  padding: 10px;
  border: none;
  text-align: center;
}
th {
  background: var(--accent-3);

  position: sticky;
  top: 0;
  z-index: 3;
  border-collapse: collapse;
}

.filters {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
input[type="date"] {
  margin: 0 auto;
  min-width: 100px;
  width: 80%;

  font-size: 18px;

  text-transform: uppercase;
  font-family: "DM Sans", sans-serif;
  border: 1px solid var(--message-box-border);
  border-radius: 3px;
  padding: 0 3px;
}
/* Large screens */
@media only screen and (min-width: 600px) {
}

/* Mid screens */
@media only screen and (max-width: 1100px) {
  .sidebar-wrap {
    width: 6rem;
  }
}
/* Small screens */
@media only screen and (max-width: 600px) {
  .content {
    gap: 0rem;
    margin-top: 10px;
  }
  .master-sheet {
    padding: 32px 20px;
  }

  .title p {
    font-size: 35px;
  }

  .controls {
    justify-content: flex-end;
    align-items: center;
  }
  .filters {
    flex-direction: column;
    gap: 1rem;
  }
}
</style>
