<template>
  <div
    @click="blur()"
    class="trip-card"
    :style="{ 'background-color': card_color }"
  >
    <div
      class="notification"
      v-if="delete_notification_opened"
      @blur="delete_notification_opened = false"
      tabindex="1"
    >
      Are you sure you want to delete a trip?
      <div class="btn-wrap">
        <div @click="toggleDeleteNotification()" class="notification-btn">
          No
        </div>
        <div @click="deleteTrip" class="notification-btn">Yes</div>
      </div>
    </div>

    <div class="card-header">
      {{
        trip_date
          .toDate()
          .toDateString()
          .replace(/^\S+\s/, "")
      }}

      <div @click="toggleMenu()" v-on:click.stop class="more-btn">
        <div
          v-if="menu_opened"
          @blur="menu_opened = false"
          tabindex="0"
          class="menu"
        >
          <div
            v-if="trip_type != 'Layover' && trip_type != 'Cancel'"
            @click="this.$parent.editTrip(card_index), test()"
            class="menu-item"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-activity"
            >
              <polygon points="16 3 21 8 8 21 3 21 3 16 16 3"></polygon>
            </svg>
            Edit
          </div>

          <div
            v-if="!payment_posted"
            @mousedown="markPaid(true)"
            class="menu-item"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-activity"
            >
              <polyline points="9 11 12 14 23 3"></polyline>
              <path
                d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"
              ></path>
            </svg>
            Mark Payment Posted
          </div>
          <div v-if="payment_posted" @click="markPaid(false)" class="menu-item">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-activity"
            >
              <polyline points="9 11 12 14 23 3"></polyline>
              <path
                d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"
              ></path>
            </svg>
            Mark Payment Not Posted
          </div>

          <div
            v-if="!driver_paid"
            @click="markDriverPaid(true)"
            class="menu-item"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-dollar-sign"
            >
              <line x1="12" y1="1" x2="12" y2="23"></line>
              <path
                d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"
              ></path>
            </svg>
            Mark Driver Paid
          </div>
          <div
            v-if="driver_paid"
            @click="markDriverPaid(false)"
            class="menu-item"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-dollar-sign"
            >
              <line x1="12" y1="1" x2="12" y2="23"></line>
              <path
                d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"
              ></path>
            </svg>
            Mark Driver Unpaid
          </div>
          <div @click="toggleDeleteNotification()" class="menu-item">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-activity"
            >
              <polyline points="3 6 5 6 21 6"></polyline>
              <path
                d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
              ></path>
              <line x1="10" y1="11" x2="10" y2="17"></line>
              <line x1="14" y1="11" x2="14" y2="17"></line>
            </svg>
            Delete
          </div>
          <div @click="generateCSV(), toggleMenu()" class="menu-item">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-activity"
            >
              <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
              <path
                d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"
              ></path>
            </svg>
            Copy Excel
          </div>
          <div class="menu-item">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-activity"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="8" y1="12" x2="16" y2="12"></line>
            </svg>
            Cancel
          </div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-more-vertical"
        >
          <circle cx="12" cy="12" r="1"></circle>
          <circle cx="12" cy="5" r="1"></circle>
          <circle cx="12" cy="19" r="1"></circle>
        </svg>
      </div>
    </div>

    <div
      v-if="
        trip_type != 'Layover' &&
        trip_type != 'Cancel' &&
        trip_type != 'Detention' &&
        trip_type != 'Other'
      "
      class="general-info"
    >
      <div class="pickup">
        <input
          v-if="edit_mode"
          v-model="pick_up_location"
          type="text"
          :placeholder="pick_up_location"
        />

        <div v-else class="address">{{ short_pickup_location }}</div>
      </div>
      <div class="arrow">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-activity"
        >
          <line x1="4" y1="12" x2="20" y2="12"></line>
          <polyline points="14 6 20 12 14 18"></polyline>
        </svg>
      </div>

      <div class="delivery">
        <input
          v-if="edit_mode"
          v-model="delivery_location"
          type="text"
          :placeholder="delivery_location"
        />
        <div v-else class="address">{{ short_delivery_location }}</div>
      </div>
    </div>

    <div class="additional-info">
      <div class="additional-info-item">
        {{ trip_type }}
      </div>
      <div v-if="company_name" class="additional-info-item">
        {{ company_name }}
      </div>
      <div v-if="driver_paid" class="additional-info-item">PAID</div>
      <div
        v-if="additional_notes"
        class="additional-info-item"
        :data-tooltip="additional_notes"
      >
        NOTE
      </div>
    </div>

    <div class="stats">
      <div class="stats-item">
        <input
          v-if="edit_mode"
          v-model="total_payment"
          type="number"
          step="0.01"
          :placeholder="total_payment"
        />

        <div v-else class="number">${{ total_payment }}</div>
        <div class="title">Driver payment</div>
      </div>

      <div class="stats-item">
        <input
          v-if="edit_mode"
          v-model="gross_income"
          type="number"
          step="0.01"
          :placeholder="gross_income"
        />

        <div v-else class="number">
          <div v-if="gross_income">${{ gross_income }}</div>
          <div v-else>-</div>
        </div>
        <div class="title">Load price</div>
      </div>

      <div class="stats-item">
        <input
          v-if="edit_mode"
          v-model="empty_miles"
          type="number"
          step="0.01"
          :placeholder="empty_miles"
        />

        <div v-else class="number">
          <div v-if="trip_type == 'Reefer' || trip_type == 'Dry'">
            {{ empty_miles }}
          </div>
          <div v-else>-</div>
        </div>
        <div class="title">Empty miles</div>
      </div>

      <div class="stats-item">
        <input
          v-if="edit_mode"
          v-model="loaded_miles"
          type="number"
          step="0.01"
          :placeholder="loaded_miles"
        />

        <div v-else class="number">
          <div v-if="trip_type == 'Reefer' || trip_type == 'Dry'">
            {{ loaded_miles }}
          </div>
          <div v-else>-</div>
        </div>
        <div class="title">Loaded miles</div>
      </div>
    </div>

    <div v-if="edit_mode" class="submit-changes-wrap">
      <div class="submit-changes-btn" @click="saveChanges()">Save Changes</div>
      <div class="submit-changes-btn" @click="toggleEditMode()">Cancel</div>
    </div>
  </div>
</template>

<script>
import { doc, setDoc, deleteDoc } from "firebase/firestore";
import { db, auth } from "@/main";
export default {
  props: [
    "doc_id",
    "card_index",
    "trip_type",
    "trip_date",

    "pick_up_location",
    "delivery_location",
    "start_location",
    "empty_miles",
    "loaded_miles",
    "total_payment",
    "company_name",
    "filterOn",
    "payment_posted",
    "gross_income",

    "empty_miles_rate",
    "driver_rate",
    "loaded_miles_payment",
    "empty_miles_payment",
    "fuel_expenses",
    "insurance_cost",
    "net_income_after_fuel",
    "net_income",
    "additional_notes",

    "driver_paid",
  ],
  data() {
    return {
      edit_mode: false,
      menu_opened: false,
      delete_notification_opened: false,
      error: "",
    };
  },
  methods: {
    test() { console.log("btch");
      console.log(  new Date(this.trip_date.toDate()));

    },
    toggleMenu() {
      this.menu_opened = !this.menu_opened;
    },
    toggleEditMode() {
      this.edit_mode = !this.edit_mode;
    },
    toggleDeleteNotification() {
      this.delete_notification_opened = !this.delete_notification_opened;
    },
    blur() {
      this.delete_notification_opened = false;
      this.menu_opened = false;
    },
    generateCSV() {
      navigator.clipboard.writeText(
        `${this.trip_date.toDate().toDateString()}	${this.company_name}	${
          this.trip_type
        }	${this.payment_posted}	${this.pick_up_location}	${
          this.delivery_location
        }	${this.empty_miles}	${this.loaded_miles}	${this.empty_miles_rate}	${
          this.gross_income
        }	${this.driver_rate}	${this.loaded_miles_payment}	${
          this.empty_miles_payment
        }	${this.total_payment}	${this.net_income}	${this.fuel_expenses}	${
          this.insurance_cost
        }	${this.net_income_after_fuel}`
      );

      this.menu_opened = false;
    },
    async saveChanges() {
      console.log("CS FIRED" + this.doc_id);
      try {
        await setDoc(
          doc(db, "trips", this.doc_id),
          {
            pick_up_location: this.pick_up_location,
            delivery_location: this.delivery_location,
            empty_miles: this.empty_miles,
            loaded_miles: this.loaded_miles,
            total_payment: this.total_payment,
          },
          { merge: true }
        );
        this.edit_mode = !this.edit_mode;
      } catch (error) {
        console.log(error);
        this.error = "Unable to save. Something went wrong.";
      }
    },
    async markPaid(value) {
      try {
        if (!auth.currentUser.uid) {
          this.error = "You must be logged in to mark paid.";
          return;
        }
        await setDoc(
          doc(
            db,
            `users/${auth.currentUser.uid}/drivers/${this.$store.state.selected_driver.id}/trips`,
            this.doc_id
          ),
          {
            payment_posted: value,
          },
          { merge: true }
        );
      } catch (error) {
        console.log(error);
        this.error = "Unable to mark paid. Something went wrong.";
      }
      this.menu_opened = false;
    },

    async markDriverPaid(value) {
      try {
        await setDoc(
          doc(
            db,
            `users/${auth.currentUser.uid}/drivers/${this.$store.state.selected_driver.id}/trips`,
            this.doc_id
          ),
          {
            driver_paid: value,
          },
          { merge: true }
        );
      } catch (error) {
        console.log(error);
        this.error = "Unable to mark paid. Something went wrong.";
      }
      this.menu_opened = false;
    },
    async deleteTrip() {
      this.delete_notification_opened = false;

      await deleteDoc(
        doc(
          db,
          `users/${auth.currentUser.uid}/drivers/${this.$store.state.selected_driver.id}/trips`,
          this.doc_id
        )
      );
      //this.$parent.removerTripCard(this.card_index);
    },
  },
  computed: {
    // a computed getter
    card_color: function () {
      /*
        "#fee4cb", //orange
        "#e9e7fd", //light purple
        "#dbf6fd", //baby blue
        "#ffd3e2", // pp0ink
        "#c8f7dc", //green
        "#d5deff", //light purple */
      let color;

      if (!this.filterOn) {
        switch (this.trip_type) {
          case "Layover" || "Cancel":
            color = "#ffd3e2"; //pp0ink
            break;
          case "Cancel":
            color = "#ffd3e2"; //pp0ink
            break;
          case "Dry":
            color = "#fee4cb"; //orange
            break;
          case "Reefer":
            color = "#dbf6fd"; //baby blue
            break;
          default:
            color = "#d5deff";
            break;
        }

        return color;
      } else {
        switch (this.payment_posted) {
          case true:
            color = "#c8f7dc"; //green
            break;

          default: // pp0ink
            color = "#ffd3e2";
            break;
        }

        if (this.trip_type == "Layover") color = "#fee4cb";

        return color;
      }
    },
    short_pickup_location: function () {
      if (this.pick_up_location) {
        let a = this.pick_up_location.split(",");
        return a[1] + ", " + a[2];
      } else {
        return " ";
      }
    },

    short_delivery_location: function () {
      if (this.delivery_location) {
        let a = this.delivery_location.split(",");
        return a[1] + ", " + a[2];
      } else {
        return " ";
      }
    },
  },
};
</script>

<style scoped>
.trip-card {
  width: 95%;
  display: flex;
  flex-direction: column;
  padding: 16px;

  background-color: #fee4cb;
  position: relative;
  border-radius: 10px;
}
.notification {
  padding: 30px 40px;
  width: 60%;
  display: flex;
  flex-direction: column;
  position: absolute;
  font-size: 24px;
  background: #fff;
  border-radius: 16px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 9;
}
.btn-wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 2rem;
}
.notification-btn {
  padding: 5px 10px;
  border-radius: 8px;
}
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /*  margin-bottom: 16px; */
  color: #4a4a4a;

  font-size: 14px;
}
.more-btn {
  color: #000;
  position: relative;
}
.menu {
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  border-radius: 8px;
  position: absolute;
  right: 0;
  z-index: 9;
  background: var(--projects-section);
  border-radius: 8px;
}
.menu-item {
  font-size: 18px;
  display: flex;
  width: 90%;

  gap: 10px;
  padding: 5px 35px;
  text-align: left;
}
.menu-item:hover,
.notification-btn:hover {
  background: rgba(226, 226, 226, 0.747);
  border-radius: 8px;
}

input {
  border: 0;
  outline: 0;
  width: 100%;
  background-color: transparent;
  border-radius: 8px;
  height: fit-content;
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  padding: 5px 10px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}
input:focus {
  border: 1px solid rgb(151, 151, 151);
}
input[type="number"] {
  width: 65%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.general-info {
  display: flex;
  justify-content: space-between;
  padding: 20px 10px;
}

.pickup,
.delivery {
  width: 100%;
  margin: 15px 0px;
  display: flex;
  justify-content: center;

  align-items: center;
  flex-direction: column;
}
.address {
  display: flex;

  text-align: center;
}
.date {
  display: flex;
  color: #4a4a4a;
  opacity: 0.7;
  font-size: 14px;
  text-align: left;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 130px;

  fill: var(--secondary-color);
}
.arrow svg {
  width: 20px;
}
.additional-info {
  display: flex;
  gap: 2px;
  margin-top: 1rem;
  flex-wrap: wrap;
}
.additional-info-item {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 15px;
  padding: 4px 12px;
  margin: 5px 0;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #fff;
  border-radius: 8px;
}

.stats {
  border-top: 1px solid white;
  display: flex;
  justify-content: space-between;

  align-items: flex-start;
  flex-direction: row;
  padding-top: 1rem;
}

.stats-item {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  padding-right: 10px;
}

.number {
  font-size: 20px;
  line-height: 32px;
  font-weight: 700;
  color: #1f1c2e;
}
.title {
  text-align: left;
  padding-right: 24px;
  font-size: 14px;

  color: #1f1c2e;
}
.submit-changes-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.submit-changes-btn {
  background-color: #fff;
  margin-top: 1rem;
  margin-right: 10px;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 17px;
  display: flex;
}
.submit-changes-btn:hover {
  border-bottom: 2px solid rgba(219, 219, 219, 0.514);
}

[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;

  opacity: 0;
  pointer-events: none;
}

/* Position tooltip above the element */
[data-tooltip]:before {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-bottom: 5px;
  margin-left: -80px;
  padding: 7px;
  width: 160px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
}

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid hsla(0, 0%, 20%, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;

  opacity: 1;
}
/* Large screens */
@media only screen and (min-width: 600px) {
}

/* Mid screens */
@media only screen and (max-width: 1100px) {
  .number {
    font-size: 17px;
  }
  .submit-changes-wrap {
    justify-content: space-around;
  }
  .trip-card {
    width: 85%;
  }
}
/* Small screens */
@media only screen and (max-width: 600px) {
  .general-info {
    padding: 0;
  }
  .additional-info {
    justify-content: space-evenly;
  }
  .additional-info-item {
    font-size: 12px;
  }

  .arrow {
    width: 20px;
    padding: 0 20px 0 10px;
    fill: var(--secondary-color);
  }

  .pickup,
  .delivery {
    align-items: center;
  }
  .address {
    font-size: 15px;
  }

  .number,
  .title {
    padding: 0;
  }
  .number {
    font-size: 15px;
    font-weight: 200;
  }
  .stats {
    padding: 1rem 0 0;
  }
  .stats-item {
    gap: 0;
  }
  .layover {
    padding: 0;
  }
}
</style>
