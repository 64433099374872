<template>
  <div class="dashboard">
    <!-- edit a card -->
    <div v-if="editing_trip" class="trip-edit-card">
      <h2>Editing a trip</h2>

      <div class="trip-item">
        <div id="text">Company Name</div>
        <input type="text" v-model="editable_trip.company_name" />
      </div>
      <div class="trip-item">
        <div id="text">Trip Date [{{ this.editable_trip.trip_date }}]</div>
        <input type="date" v-model="this.editable_trip.trip_date" />
      </div>
      <div class="trip-item">
        <div id="text">Trip Type</div>

        <select v-model="editable_trip.trip_type">
          <option value="Reefer">Reefer</option>
          <option value="Dry">Dry</option>
          <option value="Layover">Layover</option>
          <option value="Cancel">Cancel</option>
          <option value="Detention">Detention</option>
          <option value="Empty Miles">Empty Miles</option>
          <option value="Other">Other</option>
        </select>
      </div>
      <div class="trip-item">
        <div id="text">Delivery Location</div>
        <textarea
          name="delivery-location"
          v-model="editable_trip.delivery_location"
        ></textarea>
      </div>
      <div class="trip-item">
        <div id="text">You paid the driver:</div>
        <input type="number" v-model="editable_trip.total_payment" />
      </div>
      <div class="trip-item">
        <div id="text">Driver Rate</div>
        <input type="number" v-model="editable_trip.driver_rate" />
      </div>
      <div class="trip-item">
        <div id="text">Empty Miles</div>
        <input type="number" v-model="editable_trip.empty_miles" />
      </div>
      <div class="trip-item">
        <div id="text">Payment for empty miles</div>
        <input type="number" v-model="editable_trip.empty_miles_payment" />
      </div>
      <div class="trip-item">
        <div id="text">Empty Miles Rate</div>
        <input type="number" v-model="editable_trip.empty_miles_rate" />
      </div>
      <div class="trip-item">
        <div id="text">Fuel Expenses</div>
        <input type="number" v-model="editable_trip.fuel_expenses" />
      </div>
      <div class="trip-item">
        <div id="text">Gross Income</div>
        <input type="number" v-model="editable_trip.gross_income" />
      </div>
      <div class="trip-item">
        <div id="text">Insurance cost</div>
        <input type="number" v-model="editable_trip.insurance_cost" />
      </div>
      <div class="trip-item">
        <div id="text">Loaded Miles</div>
        <input type="number" v-model="editable_trip.loaded_miles" />
      </div>
      <div class="trip-item">
        <div id="text">Payment for loaded miles</div>

        <input type="number" v-model="editable_trip.loaded_miles_payment" />
      </div>
      <div class="trip-item">
        <div id="text">Net Income</div>
        <input type="number" v-model="editable_trip.net_income" />
      </div>
      <div class="trip-item">
        <div id="text">Customer payment received</div>
        <input type="checkbox" v-model="editable_trip.payment_posted" />
      </div>

      <div class="trip-item">
        <div id="text">Pickup Location</div>
        <textarea
          name="pickup-location"
          v-model="editable_trip.pick_up_location"
        ></textarea>
      </div>
      <div class="trip-item">
        <div id="text">Start Location</div>
        <textarea
          name="start-location"
          v-model="editable_trip.start_location"
        ></textarea>
      </div>
      <div class="trip-item">
        <div id="text">Total Driver Payment</div>
        <input type="number" v-model="editable_trip.total_payment" />
      </div>
      <div class="trip-item">
        <div id="text">Notes</div>
        <textarea
          name="notes"
          v-model="editable_trip.additional_notes"
        ></textarea>
      </div>
      <div class="save-btn" @click="saveChanges()">Save</div>
      <div class="cancel-btn" @click="editing_trip = false">Cancel</div>
      <p class="error-msg">{{ this.error }}</p>
      <p>
        [Warning]: If you save the changes, it will override existing data for
        the trip without recalculating. If you want to update and recalculate,
        delete the trip and add a new one.
      </p>
    </div>

    <!-- dashboard -->
    <div class="dashboard-title">
      <p>Dashboard</p>

      <p class="dashboard-date">{{ date }}</p>
    </div>
    <div class="dash-header">
      <div class="dashboard-stats">
        <div class="stats-item">
          <div id="number">
            {{ unpaid_trips }}
          </div>
          <div id="name">Payments Not Posted</div>
        </div>
        <div class="stats-item">
          <div id="number">
            {{ driver_unpaid_trips }}
          </div>
          <div id="name">Driver Unpaid Trips</div>
        </div>
        <div class="stats-item">
          <div id="number">${{ gross_income.toLocaleString("en-US") }}</div>
          <div id="name">Gross Income</div>
        </div>
        <div class="stats-item">
          <div id="number">
            ${{ Math.round(net_income).toLocaleString("en-US") }}
          </div>
          <div id="name">Net Income</div>
        </div>
      </div>
      <div class="controls">
        <div class="controls-item">
          <button
            @click="driver_menu_opened = true"
            @blur="driver_menu_opened = false"
            class="user-info"
          >
            <p>{{ this.$store.state.selected_driver.name }}</p>
            <svg
              v-if="!driver_menu_opened"
              @click="driver_menu_opened = true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-chevron-down"
            >
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
            <svg
              v-else
              @click="driver_menu_opened = false"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-chevron-up"
            >
              <polyline points="18 15 12 9 6 15"></polyline>
            </svg>
          </button>
        </div>
        <div class="controls-item" id="add-trip" @click="toggleMobileView()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-activity"
          >
            <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
            <line x1="12" y1="8" x2="12" y2="16"></line>
            <line x1="8" y1="12" x2="16" y2="12"></line>
          </svg>
        </div>
      </div>

      <div class="menu" v-if="driver_menu_opened">
        <div
          v-for="(item, index) in this.$store.state.drivers"
          :key="index"
          class="menu-item"
          @mousedown="selectDriver(index)"
        >
          <img
            src="https://lh3.googleusercontent.com/a-/AOh14GgjqaPCppTpAJ1Iqhx9lmCpsT-43zsXcbNOyUqeZA=s96-c"
          />
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="no-results" v-if="!this.$store.state.drivers.length">
      We noticed you do not have any drivers. <br />
      Start by adding a driver on the <b>Drivers</b> page
    </div>
    <div class="no-results" v-if="!this.$store.state.trips.length">
      You have no trips yet.
    </div>
    
    <div class="cards-wrap">
      <TripCard
        v-for="(item, index) in this.$store.state.trips"
        :key="index"
        :card_index="index"
        :doc_id="item.id"
        :trip_type="item.trip_type"
        :trip_date="item.trip_date"
        :pick_up_location="item.pick_up_location"
        :delivery_location="item.delivery_location"
        :start_location="item.start_location"
        :empty_miles="item.empty_miles"
        :loaded_miles="item.loaded_miles"
        :total_payment="item.total_payment"
        :company_name="item.company_name"
        :payment_posted="item.payment_posted"
        :gross_income="item.gross_income"
        :empty_miles_rate="item.empty_miles_rate"
        :driver_rate="item.driver_rate"
        :loaded_miles_payment="item.loaded_miles_payment"
        :empty_miles_payment="item.empty_miles_payment"
        :fuel_expenses="item.fuel_expenses"
        :insurance_cost="item.insurance_cost"
        :net_income_after_fuel="item.net_income_after_fuel"
        :net_income="item.net_income"
        :driver_paid="item.driver_paid"
        :additional_notes="item.additional_notes"
        :filterOn="filterOn"
      />
    </div>
  </div>
</template>

<script>
import TripCard from "./trip-card.vue";
import TripRow from "./trip-row.vue";

import { db, auth } from "../main";
//import { getFunctions, httpsCallable } from "firebase/functions";
import {
  collection,
  onSnapshot,
  getDocs,
  doc,
  addDoc,
  Timestamp,
  setDoc,
} from "firebase/firestore";

export default {
  name: "Dash",
  components: { TripCard, TripRow },
  data() {
    return {
      trips: [],
      filterOn: true,
      refresh_active: false,
      unsub: null,
      driver_menu_opened: false,
      editing_trip: false,
      editable_trip: {},
      error: null,
    };
  },
  methods: {
    async rewrite() {
      if (!auth.currentUser) return;
      //get a collection from Firestore
      const querySnapshot = await getDocs(
        collection(db, "drivers/7GLepArepQ9FxCHUeoGB/trips")
      );
      querySnapshot.forEach(async (doc) => {
        await addDoc(
          collection(
            db,
            `users/UnS9KxEZWqRJZg0OqtAbZJ5oAEq2/drivers/7GLepArepQ9FxCHUeoGB/trips`
          ),
          { ...doc.data() }
        );
      });
    },
    /**
     * Takes a trip index and opens the edit menu for it
     * @param {*} index any valid trip index
     */
    editTrip(index) {
      this.editing_trip = true;
      this.editable_trip = {
        ...this.$store.state.trips[index],
      };

      //convert the timestamp to Date string
      this.editable_trip.trip_date = this.editable_trip.trip_date
        .toDate()
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "-");
    },
    async saveChanges() {
      if (
        this.editable_trip &&
        Object.keys(this.editable_trip).length === 0 &&
        Object.getPrototypeOf(this.editable_trip) === Object.prototype
      ) {
        return;
      }

      try {
        await setDoc(
          doc(
            db,
            `users/${auth.currentUser.uid}/drivers/${this.$store.state.selected_driver.id}/trips`,
            this.editable_trip.id
          ),
          {
            start_location: this.editable_trip.start_location,
            insurance_cost: this.editable_trip.insurance_cost,
            company_name: this.editable_trip.company_name,

            delivery_location: this.editable_trip.delivery_location,
            driver_rate: this.editable_trip.driver_rate,
            empty_miles: this.editable_trip.empty_miles,
            empty_miles_payment: this.editable_trip.empty_miles_payment,
            empty_miles_rate: this.editable_trip.empty_miles_rate,
            fuel_expenses: this.editable_trip.fuel_expenses,
            gross_income: this.editable_trip.gross_income,
            loaded_miles: this.editable_trip.loaded_miles,
            loaded_miles_payment: this.editable_trip.loaded_miles_payment,
            net_income: this.editable_trip.net_income,
            net_income_after_fuel: this.editable_trip.net_income_after_fuel,
            payment_posted: this.editable_trip.payment_posted,
            pick_up_date: this.editable_trip.pick_up_date,
            pick_up_location: this.editable_trip.pick_up_location,
            start_location: this.editable_trip.start_location,
            total_payment: this.editable_trip.total_payment,
            trip_date: Timestamp.fromDate(
              new Date(
                new Date(this.editable_trip.trip_date).setHours(24, 0, 0, 0)
              )
            ),

            trip_type: this.editable_trip.trip_type,
            additional_notes: this.editable_trip.additional_notes,
          },
          { merge: true }
        );
        this.editing_trip = !this.editing_trip;
      } catch (error) {
        console.log(error);
        this.error = "Unable to save. Something went wrong.";
      }
    },
    /* async transfer() {
      try {
        let transfer_trips = [];

        const querySnapshot = await getDocs(collection(db, "trips"));

        querySnapshot.forEach((doc) => {
          transfer_trips.push(doc.data());
        });
       

        try {
          transfer_trips.forEach(async (doc) => {
            
            await addDoc(collection(db, "drivers/ZKtv2KduxtSLjVgZKCg8/trips"), {
              start_location: doc.start_location,
              insurance_cost: null,
              company_name: doc.company_name,
              delivery_date: doc.delivery_date,
              delivery_location: doc.delivery_location,
              driver_rate: doc.driver_rate,
              empty_miles: doc.empty_miles,
              empty_miles_payment: doc.empty_miles_payment,
              empty_miles_rate: doc.empty_miles_rate,
              fuel_expenses: doc.fuel_expenses,
              gross_income: doc.gross_income,
              loaded_miles: doc.loaded_miles,
              loaded_miles_payment: doc.loaded_miles_payment,
              net_income: doc.net_income,
              net_income_after_fuel: doc.net_income_after_fuel,
              payment_posted: doc.payment_posted,
              pick_up_date: doc.pick_up_date,
              pick_up_location: doc.pick_up_location,
              total_payment: doc.total_payment,
              trip_date: doc.trip_date,
              trip_number: doc.trip_number,
              trip_type: doc.trip_type,
              additional_notes: doc.additional_notes
                ? doc.additional_notes
                : null,
            });
          });
        } catch (e) {
          console.log(e);
        }
      } catch (error) {
        console.log(error);
      }
    }, */
    toggleFilter() {
      this.filterOn = !this.filterOn;
    },
    toggleMobileView() {
      this.$store.state.view_add_trip = !this.$store.state.view_add_trip;
    },

    removerTripCard(card_index) {
      this.trips.splice(card_index, 1);
    },

    /**
     * [Future expansion]: Pass a driver id to fetch driver specific data.
     */
    async getTrips() {
      this.refresh_active = true;
      if (this.unsub) this.unsub();
      this.$store.state.trips = [];
      try {
        this.unsub = onSnapshot(
          collection(
            db,
            `users/${auth.currentUser.uid}/drivers/${this.$store.state.selected_driver.id}/trips`
          ),
          (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
              let index = this.$store.state.trips.findIndex(
                (obj) => obj.id == change.doc.id
              );
              //add ID to the data
              let temp = change.doc.data();
              temp.id = change.doc.id;

              if (change.type == "added" && index == -1) {
                this.$store.dispatch("addTrip", temp);
                //console.log("Trip added: " + change.doc.id);

                return;
              }

              if (change.type == "modified") {
                //remove element before updating
                this.$store.state.trips.splice(index, 1);
                this.$store.dispatch("addTrip", temp);

                console.log("Trip modified: " + change.doc.id);
              } else if (change.type == "removed") {
                //remove deleted trip
                this.$store.state.trips.splice(index, 1);
                console.log("Trip removed: " + change.doc.id);
              }
            });
          }
        );
      } catch (error) {
        console.log(error);
      }

      this.refresh_active = false;
    },

    async selectDriver(index) {
      console.log(index);
      this.$store.state.selected_driver = this.$store.state.drivers[index];
      this.driver_menu_opened = false;
      await this.getTrips();
    },
  },
  computed: {
    // a computed getter
    date: function () {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const d = new Date();
      return `${monthNames[d.getMonth()]}, ${d.getDate()}`;
    },

    /* converted_date_obj() {
      return this.editable_trip.trip_date
        .toDate()
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "-");
    }, */
    // a computed getter
    unpaid_trips: function () {
      let paid = this.$store.state.trips.filter(
        (item) =>
          !item.payment_posted &&
          (item.trip_type == "Reefer" || item.trip_type == "Dry")
      );
      return `${paid.length}`;
    },
    driver_unpaid_trips: function () {
      let paid = this.$store.state.trips.filter((item) => !item.driver_paid);
      return `${paid.length}`;
    },
    total_loaded_miles: function () {
      let total = 0;
      this.$store.state.trips.forEach((element) => {
        total += element.loaded_miles;
      });

      return total;
    },
    gross_income: function () {
      let total = 0;
      this.$store.state.trips.forEach((element) => {
        total += element.gross_income;
      });

      return total;
    },
    net_income: function () {
      let total = 0;
      this.$store.state.trips.forEach((element) => {
        total += element.net_income;
      });

      return total;
    },
  },
};
</script>
<style scoped>
.no-results {
  font-size: 1.5rem;
  background: var(--accent-1);
  border-radius: 6px;
  padding: 16px;
}
.dashboard {
  flex: 2;
  background-color: var(--projects-section);
  border-radius: 32px;
  padding: 32px 32px;
  position: relative;
  width: 95%;
  height: 80vh;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.dashboard-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  color: var(--main-color);
}
.dashboard-title p {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  opacity: 0.9;
  margin: 0;
  color: var(--main-color);
}
.dashboard-title .dashboard-date {
  font-size: 20px;
}
.dash-header {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.controls {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 16px;
  margin: 10px 0;
}

.controls-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  border-radius: 4px;
  background-color: transparent;
  border: none;
  color: var(--main-color);
  width: 100px;
  transition: 0.2s;
}
.controls-item:hover {
  background-color: var(--shadow);
}

#add-trip {
  visibility: hidden;
}
.cards-wrap {
  width: 100%;
  gap: 1rem;
  border-radius: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
}

.dashboard-stats {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 16px;

  width: 100%;
}

.stats-item {
  display: flex;
  flex-direction: column;

  align-items: flex-start;
}

#name {
  position: relative;
  /* padding-right: 24px; */
  font-size: 14px;
  color: var(--secondary-color);
}
#number {
  font-size: 18px;
  line-height: 32px;
  font-weight: 700;
  color: var(--main-color);
}

.user-info {
  display: flex;
  align-items: center;

  gap: 10px;
  padding: 0;
  border: 0;
  background-color: transparent;

  position: relative;
}
.user-info img {
  width: 32px;
  height: 32px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 4px;
}
.user-info span {
  color: var(--main-color);
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
.user-info svg {
  cursor: pointer;
}
.menu-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 8px;
  cursor: pointer;
  justify-content: space-between;
}
.menu-item:hover {
  background-color: rgb(243, 243, 243);
  border-radius: 16px;
}

.menu-item img {
  width: 32px;
  height: 32px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 4px;
}

.menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  z-index: 4;
  font-size: 18px;
  padding: 16px 32px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 rgba(136, 148, 171, 0.2),
    0 24px 20px -24px rgba(71, 82, 107, 0.1);
  left: 2rem;
  top: 15rem;
}

.trip-edit-card {
  position: absolute;
  z-index: 4;

  background-color: #fff;

  width: 95%;
  height: 80vh;
  overflow: scroll;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.trip-item {
  display: flex;
  width: 95%;
  padding: 0 5px;
  align-items: center;
  gap: 1rem;
  text-align: left;
  justify-content: space-between;
}
.trip-item #text {
  width: 150px;
}

.save-btn {
  width: 100px;
  border-radius: 16px;
  padding: 16px 32px;
  background-color: var(--accent-2);
}
.save-btn:hover,
.cancel-btn:hover {
  border: 1px solid rgb(214, 214, 214);
}
.cancel-btn {
  width: 100px;
  border-radius: 16px;
  padding: 16px 32px;
  background-color: var(--accent-1);
}

/* Large screens */
@media only screen and (min-width: 600px) {
}

/* Mid screens */
@media only screen and (max-width: 1100px) {
  #add-trip {
    visibility: visible;
  }
  .menu {
    left: 2rem;
  }
}
/* Small screens */
@media only screen and (max-width: 600px) {
  .dashboard {
    padding: 32px 20px;
  }
  .dashboard-title {
    margin-bottom: 0;
  }
  .dashboard-title p {
    font-size: 28px;
  }
  .dashboard-stats {
    padding: 0;
    justify-content: none;
  }
  .dash-header {
    gap: 20px;
    flex-direction: column;
    margin-top: 2rem;
  }
  #name {
    padding: 0;
  }
  .dashboard-date {
    font-size: 20px;
  }
  .stats-item {
    align-items: center;
    padding: 0 10px;
  }
  .controls {
    width: 100%;
    margin: 0;
  }

  .menu {
    left: 1rem;
    top: 16rem;
  }
}
</style>
