<template>
  <div class="content">
    <Sidebar />
    <div class="drivers">
      <div v-if="adding_driver" class="absolute-modal">
        <div class="form">
          <input
            v-model="new_driver_name"
            placeholder="Driver's Name"
            type="text"
          />
        </div>
        <div class="controls">
          <div class="controls-item" @click="createDriver()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-check"
            >
              <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
            Add
          </div>
          <div class="controls-item" @click="toggleAddingDriver()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
            Cancel
          </div>
        </div>
        <div class="error-msg">{{ error_msg }}</div>
      </div>

      <div v-if="removing_driver" class="absolute-modal">
        <h3>Are you sure you want to remove {{ selected_driver.name }}?</h3>
        <div class="controls">
          <div class="controls-item" @click="removeDriver(selected_driver.id)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-check"
            >
              <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
            Remove
          </div>
          <div class="controls-item" @click="cancelRemoving()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
            Cancel
          </div>
        </div>
        <div class="error-msg">{{ error_msg }}</div>
      </div>

      <div v-if="editing_driver" class="absolute-modal">
        <h3>Editing {{ selected_driver.name }}?</h3>
        <div class="form">
          <input
            v-model="new_driver_name"
            placeholder="Driver's Name"
            type="text"
          />
        </div>
        <div class="controls">
          <div
            class="controls-item"
            @click="saveEditedDriver(selected_driver.id)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-check"
            >
              <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
            Save
          </div>
          <div class="controls-item" @click="editing_driver = false">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
            Cancel
          </div>
        </div>
        <div class="error-msg">{{ error_msg }}</div>
      </div>

      <div class="title">
        <p>Drivers</p>
      </div>

      <div class="driver-list">
        <div
          class="driver-card"
          v-for="(item, index) in this.$store.state.drivers"
          :key="index"
        >
          <div id="driver-info">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-user"
            >
              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
            </svg>
            {{ item.name }}
          </div>
          <div class="controls">
            <div class="controls-item" @click="selectDriver(index, 'remove')">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-trash-2"
              >
                <polyline points="3 6 5 6 21 6"></polyline>
                <path
                  d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                ></path>
                <line x1="10" y1="11" x2="10" y2="17"></line>
                <line x1="14" y1="11" x2="14" y2="17"></line>
              </svg>
            </div>
            <div class="controls-item" @click="selectDriver(index, 'edit')">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-edit-2"
              >
                <path
                  d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"
                ></path>
              </svg>
            </div>
          </div>
          <div class="additional-info"></div>
        </div>
      </div>
      <div class="add-driver-wrap">
        <div class="add-driver" @click="toggleAddingDriver()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-plus"
          >
            <line x1="12" y1="5" x2="12" y2="19"></line>
            <line x1="5" y1="12" x2="19" y2="12"></line>
          </svg>
          Add a driver
        </div>
      </div>
      <div class="error-msg">{{ error_msg }}</div>
    </div>
    <!-- <div class="controls">
        <div class="controls-item" @click="toggleFilter()" v-if="filterOn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-eye"
          >
            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
            <circle cx="12" cy="12" r="3"></circle>
          </svg>
        </div>
        <div class="controls-item" @click="toggleFilter()" v-else>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-eye-off"
          >
            <path
              d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"
            ></path>
            <line x1="1" y1="1" x2="23" y2="23"></line>
          </svg>
        </div>
        <div class="controls-item" @click="downloadCSV('master')">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-download"
          >
            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
            <polyline points="7 10 12 15 17 10"></polyline>
            <line x1="12" y1="15" x2="12" y2="3"></line>
          </svg>
        </div>
        <div class="filters">
          <div class="filter">
            <div id="title">Start Date</div>
            <input type="date" v-model="start_date" />
          </div>
          <div class="filter">
            <div id="title">End Date</div>
            <input type="date" v-model="end_date" />
          </div>
        </div>
      </div> -->
  </div>
</template>

<script>
//stopped at trying to get confirmation before removing a driver
// no hangers [!]

// @ is an alias to /src
import Sidebar from "@/components/sidebar.vue";
import { addDoc, collection, setDoc, doc } from "@firebase/firestore";
import { db, auth } from "@/main";
export default {
  name: "MasterView",
  components: { Sidebar },
  data() {
    return {
      adding_driver: false,
      new_driver_name: null,

      editing_driver: false,

      removing_driver: false,
      selected_driver: null,
      error_msg: "",
    };
  },

  methods: {
    toggleAddingDriver() {
      this.adding_driver = !this.adding_driver;
    },
    toggleEditingDriver() {
      this.editing_driver = !this.editing_driver;
    },
    cancelRemoving() {
      this.selected_driver = null;
      this.removing_driver = false;
    },

    /**
     * Takes the index of the driver in Store and initiates conformation pop-up.
     * @param {*} index  index of the driver in Store
     * @param {*} mode popup selector, accepted values are *edit* and *remove*
     */
    selectDriver(index, mode) {
      this.selected_driver = this.$store.state.drivers[index];
      this.selected_driver.index = index;
      switch (mode) {
        case "edit":
          this.editing_driver = true;

          break;
        case "remove":
          this.removing_driver = true;
          console.log("fire");
          break;
        default:
          console.warn("Unknown mode.");
          break;
      }
    },
    async removeDriver(id) {
      try {
        await setDoc(
          doc(db, `users/${auth.currentUser.uid}/drivers`, `${id}`),
          {
            removed: true,
          },
          { merge: true }
        );
        this.$store.state.drivers.splice(this.selected_driver.index, 1);
        this.selected_driver = null;
        this.removing_driver = false;
      } catch (error) {
        console.log(error);
        this.error_msg = "Something went wrong. Unable to remove a driver.";
      }
    },

    async saveEditedDriver(id) {
      if (!this.new_driver_name)
        return (this.error_msg = "Make sure to fill out all of the fields.");

      try {
        await setDoc(
          doc(db, `users/${auth.currentUser.uid}/drivers`, `${id}`),
          {
            name: this.new_driver_name,
          },
          { merge: true }
        );
        this.$store.state.drivers[this.selected_driver.index].name =
          this.new_driver_name;
        this.new_driver_name = null;
        this.editing_driver = false;
      } catch (error) {
        console.log(error);
        this.error_msg = "Something went wrong. Unable to add a driver.";
      }
    },
    async createDriver() {
      if (!this.new_driver_name)
        return (this.error_msg = "Make sure to fill out all of the fields.");

      try {
        const docRef = await addDoc(collection(db, `users/${auth.currentUser.uid}/drivers`), {
          name: this.new_driver_name,
        });
        this.$store.dispatch("addDriver", {
          name: this.new_driver_name,
          id: docRef.id,
        });
        this.new_driver_name = null;
        this.adding_driver = false;
      } catch (error) {
        console.log(error);
        this.error_msg = "Something went wrong. Unable to add a driver.";
      }
    },
  },
  computed: {
    filteredTrips: function () {
      let trips_between_dates = this.$store.state.trips.filter(
        (item) =>
          new Date(item.trip_date.toDate()) > new Date(this.start_date) &&
          new Date(item.trip_date.toDate()) < new Date(this.end_date)
      );

      console.log(trips_between_dates);
      return trips_between_dates;
    },
  },
};
</script>

<style scoped>
.content {
  margin-top: 2rem;
  width: 99%;
  max-width: 1800px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  gap: 10px;
}
.drivers {
  flex: 2;
  background-color: var(--projects-section);
  border-radius: 32px;
  padding: 32px 32px;
  width: 95%;
  height: 80vh;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  position: relative;
}
.absolute-modal {
  position: absolute;
  top: 2rem;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  background: white;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.close-btn {
  display: flex;

  justify-content: flex-end;
  margin-right: 1rem;
}
.close-btn svg {
  width: 30px;
}
.form {
  margin-top: 3rem;
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.title p {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  opacity: 0.9;
  margin: 0;
  color: var(--main-color);
}

.controls {
  display: flex;
  flex-direction: row;

  margin: 10px 0;
}
.controls-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  border-radius: 4px;
  background-color: transparent;
  border: none;
  color: var(--main-color);
  margin-left: 8px;
  transition: 0.2s;
}

.controls-item:hover {
  background-color: var(--shadow);
}

#info {
  display: flex;
  max-width: 400px;
}
#name {
  font-size: 26px;
}
.driver-list {
  margin-top: 5rem;
  display: flex;
  gap: 10px;
  justify-content: space-around;
  flex-wrap: wrap;
}
.driver-card {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-direction: column;
  padding: 8px 16px;
  width: 200px;
}

.filters {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#title {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding: 22px 10px;
  opacity: 0.9;
  color: var(--main-color);
  justify-content: center;
}
.section #title {
  width: 100%;
  padding: 10px 10px;
  margin-left: 2rem;

  color: var(--main-color);
  text-align: left;
  font-size: 19px;
}
#driver-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  font-size: 25px;
}
#driver-info svg {
  width: 2rem;
}
.add-driver-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
}
.add-driver {
  display: flex;
  flex-direction: column;
  padding: 32px 32px;
  background-color: rgb(233, 233, 233);
  font-size: 18px;
  max-width: 200px;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
}

.add-driver:hover {
  background-color: rgb(219, 219, 219);
}

/* Large screens */
@media only screen and (min-width: 600px) {
}

/* Mid screens */
@media only screen and (max-width: 1100px) {
  .sidebar-wrap {
    width: 6rem;
  }
}
/* Small screens */
@media only screen and (max-width: 600px) {
  .content {
    gap: 0rem;
    margin-top: 10px;
  }
  .drivers {
    padding: 32px 20px;

  }

  .title p {
    font-size: 35px;
  }

  .controls {
    justify-content: flex-end;
    align-items: center;
    gap: 2rem;
  }

  .filters {
    flex-direction: column;
    gap: 1rem;
  }
  #driver-info {
    font-size: 16px;
  }
}
</style>
