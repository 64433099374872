<template>
  <div class="header">
    <div class="logo">Benefit<span>Transportation</span></div>
    <div class="nav">
      <div class="nav-item" id="contact">
        <a href="#" @click="scrollTo()">Contact</a>
      </div>
      <div class="nav-item" id="how-it-works">
        <a href="#" >How it works</a>
      </div>
      <div class="nav-item" id="quote">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.35156 14.4219C6.46094 17.0703 2.92969 17.0703 2.92969 17.0703C2.92969 17.0703 2.92969 13.5391 5.57812 12.6484"
            stroke="#F8F9FF"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M15.3047 8.23447L10 13.5392L6.46094 10.0001L11.7656 4.6954C13.7734 2.68759 15.7813 2.71103 16.6406 2.83603C16.7732 2.85372 16.8963 2.91456 16.9909 3.00916C17.0855 3.10376 17.1464 3.22686 17.1641 3.35947C17.2891 4.21884 17.3125 6.22665 15.3047 8.23447Z"
            stroke="#F8F9FF"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M14.4219 9.11719V14.1641C14.4195 14.3289 14.3521 14.4862 14.2344 14.6016L11.7109 17.1328C11.6309 17.2128 11.5306 17.2695 11.4208 17.297C11.3111 17.3244 11.1959 17.3215 11.0876 17.2886C10.9794 17.2558 10.882 17.1941 10.8061 17.1102C10.7301 17.0264 10.6783 16.9235 10.6562 16.8125L10 13.5391"
            stroke="#F8F9FF"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.8828 5.57812H5.83588C5.67102 5.58047 5.51377 5.64787 5.39838 5.76563L2.86713 8.28906C2.78716 8.36912 2.73043 8.46939 2.70299 8.57916C2.67554 8.68894 2.67842 8.8041 2.7113 8.91237C2.74418 9.02064 2.80584 9.11796 2.8897 9.19393C2.97355 9.2699 3.07646 9.32168 3.18744 9.34375L6.46088 10"
            stroke="#F8F9FF"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <a href="#" @click="scrollTo()">Get a Quote</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
  methods: {
    scrollTo() {
      //call parent function to scroll to the element
      //scroll to bottom of #app
      console.log(this.$parent);
      this.$parent.scrollTo('quote_bottom');
      

    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;

  background: #f8f9ff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 130%;
  /* or 27px */

  text-align: center;

  color: #2d2d2d;
}

.logo span {
  font-weight: 400;
}

.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.nav-item {
  display: flex;

  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 25px;
  gap: 8px;

  border-radius: 14px;

  &:hover {
    /*scale down the btn*/
    transform: scale(0.96);
    transition: all 0.3s ease-in-out;
  }
}

.nav-item a {
  all: unset;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;
  font-feature-settings: "liga" off;

  cursor: pointer;
}
#contact {
  color: #504cf8;
}

#how-it-works {
  color: #504cf8;
  background: rgba(80, 76, 248, 0.1);
  display: none;
}

#quote {
  background: #504cf8;

  /* White */

  color: #ffffff;
}

/* Large screens */
@media only screen and (min-width: 600px) {
}

/* Mid screens */
@media only screen and (max-width: 1100px) {
  .header {
    padding: 20px 30px;
  }
  .logo img {
    width: 14rem;
    height: auto;
  }

  .nav-item {
    padding: 16px 30px;
  }

  #how-it-works,
  #quote {
    padding: 10px 20px;
    border-radius: 15px;
  }
}
/* Small screens */
@media only screen and (max-width: 840px) {
  .header {
    padding: 20px 10px;
    justify-content: center;
  }

  #how-it-works,
  #contact,
  #quote {
    display: none;
  }
}
</style>
