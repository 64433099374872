<template>
  <tr class="trip-row" :style="{ 'background-color': card_color }">
    <td>
      {{
        trip_date
          .toDate()
          .toDateString()
          .replace(/^\S+\s/, "")
      }}
    </td>
    <td>{{ company_name }}</td>

    <td>{{ trip_type }}</td>

    <td v-if="payment_posted">Yes</td>
    <td v-else>No</td>

    <td v-if="pick_up_location">{{ short_pickup_location }}</td>
    <td v-else>-</td>

    <td v-if="delivery_location">{{ short_delivery_location }}</td>
    <td v-else>-</td>

    <td v-if="empty_miles">{{ empty_miles }}</td>
    <td v-else>-</td>

    <td v-if="loaded_miles">{{ loaded_miles }}</td>
    <td v-else>-</td>

    <td v-if="empty_miles_rate">{{ empty_miles_rate }}</td>
    <td v-else>-</td>

    <td v-if="gross_income">{{ gross_income }}</td>
    <td v-else>-</td>

    <td v-if="driver_rate">{{ driver_rate }}</td>
    <td v-else>-</td>

    <td v-if="empty_miles_payment">{{ empty_miles_payment }}</td>
    <td v-else>-</td>
    <td v-if="loaded_miles_payment">{{ loaded_miles_payment }}</td>
    <td v-else>-</td>

    <td v-if="total_payment">{{ total_payment }}</td>
    <td v-else>-</td>

    <td v-if="net_income">{{ net_income }}</td>
    <td v-else>-</td>

    <td v-if="driver_paid" :style="{ 'background-color': '#c8f7dc' }">Yes</td>
    <td v-else :style="{ 'background-color': '#ffd3e2' }">No</td>
  </tr>
</template>

<script>
import { doc, setDoc, deleteDoc } from "firebase/firestore";
import { db, auth } from "@/main";
export default {
  props: [
    "doc_id",
    "card_index",
    "trip_type",
    "trip_date",

    "pick_up_location",
    "delivery_location",
    "start_location",
    "empty_miles",
    "loaded_miles",
    "total_payment",
    "company_name",
    "filterOn",
    "payment_posted",
    "gross_income",

    "empty_miles_rate",
    "driver_rate",
    "loaded_miles_payment",
    "empty_miles_payment",
    "fuel_expenses",
    "insurance_cost",
    "net_income_after_fuel",
    "net_income",

    "driver_paid",
  ],
  data() {
    return {
      edit_mode: false,
      menu_opened: false,
      delete_notification_opened: false,
      error: "",
    };
  },
  methods: {
    toggleMenu() {
      this.menu_opened = !this.menu_opened;
    },
    toggleEditMode() {
      this.edit_mode = !this.edit_mode;
    },
    toggleDeleteNotification() {
      this.delete_notification_opened = !this.delete_notification_opened;
    },
    generateCSV() {
      navigator.clipboard.writeText(
        `${this.trip_date.toDate().toDateString()}	${this.company_name}	${
          this.trip_type
        }	${this.payment_posted}	${this.pick_up_location}	${
          this.delivery_location
        }	${this.empty_miles}	${this.loaded_miles}	${this.empty_miles_rate}	${
          this.gross_income
        }	${this.driver_rate}	${this.loaded_miles_payment}	${
          this.empty_miles_payment
        }	${this.total_payment}	${this.net_income}	${this.fuel_expenses}	${
          this.insurance_cost
        }	${this.net_income_after_fuel}`
      );

      this.menu_opened = false;
    },
    async saveChanges() {
      console.log("CS FIRED" + this.doc_id);
      try {
        await setDoc(
          doc(db, "trips", this.doc_id),
          {
            pick_up_location: this.pick_up_location,
            delivery_location: this.delivery_location,
            empty_miles: this.empty_miles,
            loaded_miles: this.loaded_miles,
            total_payment: this.total_payment,
          },
          { merge: true }
        );
        this.edit_mode = !this.edit_mode;
      } catch (error) {
        console.log(error);
        this.error = "Unable to save. Something went wrong.";
      }
    },
    async markPaid(value) {
      try {
        await setDoc(
          doc(db, "trips", this.doc_id),
          {
            payment_posted: value,
          },
          { merge: true }
        );
      } catch (error) {
        console.log(error);
        this.error = "Unable to mark paid. Something went wrong.";
      }
      this.menu_opened = false;
    },

    async markDriverPaid(value) {
      try {
        await setDoc(
          doc(db, "trips", this.doc_id),
          {
            driver_paid: value,
          },
          { merge: true }
        );
      } catch (error) {
        console.log(error);
        this.error = "Unable to mark paid. Something went wrong.";
      }
      this.menu_opened = false;
    },
    async deleteTrip() {
      this.delete_notification_opened = false;

      await deleteDoc(doc(db, "trips", this.doc_id));
      //this.$parent.removerTripCard(this.card_index);
    },
  },
  computed: {
    // a computed getter
    card_color: function () {
      let color;

      if (this.filterOn) {
        switch (this.trip_type) {
          case "Layover" || "Cancel":
            color = "#ffd3e2"; //pp0ink
            break;
          case "Cancel":
            color = "#ffd3e2"; //pp0ink
            break;
          case "Dry":
            color = "#fee4cb"; //orange
            break;
          case "Reefer":
            color = "#dbf6fd"; //baby blue
            break;
          default:
            color = "#d5deff";
            break;
        }

        return color;
      } else {
        if (this.card_index % 2 == 0) {
          return "#fcfcfc";
        } else {
          return "#fff";
        }
      }
    },
    short_pickup_location: function () {
      let a = this.pick_up_location.split(",");
      return a[1] + ", " + a[2];
    },

    short_delivery_location: function () {
      let a = this.delivery_location.split(",");
      return a[1] + ", " + a[2];
    },
  },
};
</script>

<style scoped>
.trip-row {
  width: 100%;

  padding: 16px;

  position: relative;
  border-radius: 4px;
  border-bottom: 1px solid rgb(206, 206, 206);
  border-right: 1px solid rgb(206, 206, 206);
  border-left: 1px solid rgb(206, 206, 206);
}
td {
  width: fit-content;
  padding: 0 20px;
}
/* Large screens */
@media only screen and (min-width: 600px) {
}

/* Mid screens */
@media only screen and (max-width: 1100px) {
}
/* Small screens */
@media only screen and (max-width: 600px) {
}
</style>
