<template>
  <div
    class="app-header"
    v-if="this.$route.path != '/' && this.$route.path != '/login'"
  >
    <div class="app-header-left">
      <div class="logo">BenefitTrack</div>

      <!-- <div class="search-wrapper">
        <input class="search-input" type="text" placeholder="Search" />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          class="feather feather-search"
          viewBox="0 0 24 24"
        >
          <defs></defs>
          <circle cx="11" cy="11" r="8"></circle>
          <path d="M21 21l-4.35-4.35"></path>
        </svg>
      </div> -->
    </div>
    <div class="app-header-right">
      <button
        @click="toggleDarkMode()"
        :style="this.dark_mode ? 'fill:var(--main-color)' : 'fill:none'"
        class="mode-switch"
        title="Switch Theme"
      >
        <svg
          class="moon"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <defs></defs>
          <path d="M21 12.79A9 9 0 1111.21 3 7 7 0 0021 12.79z"></path>
        </svg>
      </button>
      <!--  <button class="add-btn" title="Add New Project">
        <svg class="btn-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round">
          <line x1="12" y1="5" x2="12" y2="19"></line>
          <line x1="5" y1="12" x2="19" y2="12"></line></svg>
      </button>
      <button class="notification-btn">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bell">
          <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
          <path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg>
      </button> -->
      <button
        @click="logout_visible = true"
        @blur="logout_visible = false"
        class="profile-btn"
      >
        <img :src="user_photo" />
        <span>{{ username }}</span>
      </button>
      <div v-if="logout_visible" class="logout-menu">
        <a @click="signOut()" href="#" class="nav-link">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-activity"
          >
            <path d="M10 22H5a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h5"></path>
            <polyline points="17 16 21 12 17 8"></polyline>
            <line x1="21" y1="12" x2="9" y2="12"></line>
          </svg>
          <div class="link-text">Log Out</div>
        </a>
      </div>
    </div>
  </div>
  <router-view />
</template>
<script>
import { collection, onSnapshot, getDocs } from "firebase/firestore";

import { db, auth } from "./main";
export default {
  data() {
    return {
      unsub: null,
      logout_visible: false,
      dark_mode: false,
    };
  },

  methods: {
    async getDrivers() {
      try {
        this.$store.state.drivers = [];
        const querySnapshot = await getDocs(
          collection(db, `users/${auth.currentUser.uid}/drivers`)
        );

        querySnapshot.forEach((doc) => {
          if (!doc.data().removed) {
            let temp = doc.data();
            temp.id = doc.id;
            this.$store.dispatch("addDriver", temp);
          }
        });
      } catch (error) {
        console.log(error);
      }
    },

    async getTripsOnload() {
      this.refresh_active = true;
      if (this.unsub) this.unsub();
      this.$store.state.trips = [];

      if (!this.$store.state.selected_driver.length) {
        this.$store.dispatch("addSelectedDriver", this.$store.state.drivers[0]);
      }

      try {
        this.unsub = onSnapshot(
          collection(
            db,
            `users/${auth.currentUser.uid}/drivers/${this.$store.state.selected_driver.id}/trips`
          ),
          (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
              let index = this.$store.state.trips.findIndex(
                (obj) => obj.id == change.doc.id
              );
              //add ID to the data
              let temp = change.doc.data();
              temp.id = change.doc.id;

              if (change.type == "added" && index == -1) {
                this.$store.dispatch("addTrip", temp);
                //console.log("Trip added: " + change.doc.id);

                return;
              }

              if (change.type == "modified") {
                //remove element before updating
                this.$store.state.trips.splice(index, 1);
                this.$store.dispatch("addTrip", temp);
              } else if (change.type == "removed") {
                //remove deleted trip
                this.$store.state.trips.splice(index, 1);
              }
            });
          }
        );
      } catch (error) {
        console.log(error);
      }

      this.refresh_active = false;
    },
    async signOut() {
      auth
        .signOut()
        .then(() => {
          localStorage.clear();
          this.logout_visible = false;

          this.$router.push("/");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async toggleDarkMode() {
      this.dark_mode = !this.dark_mode;
    },
  },
  computed: {
    username: function () {
      if (auth.currentUser == null) return "";
      return auth.currentUser.displayName;
    },
    user_photo: function () {
      if (auth.currentUser == null) return "";
      return auth.currentUser.photoURL;
    },
  },
  async mounted() {
    if (auth && auth.currentUser) {
      //this.$router.push("/master");
      await this.getDrivers();
      await this.getTripsOnload();

      /* while (this.unsub == null)
        this.$nextTick(function () {
          window.setInterval(() => {
            console.log("TRY");
            this.getTrips();
          }, 1000);
        }); */
    }
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=DM+Sans&family=Montserrat:wght@100;200;300;500;700&display=swap");

:root {
  --app-container: rgb(253, 253, 253);
  --main-color: #1f1c2e;
  --secondary-color: #4a4a4a;
  --link-color: #1f1c2e;
  --link-color-hover: #c3cff4;
  --link-color-active: #fff;
  --link-color-active-bg: #1f1c2e;
  --projects-section: #fff;
  --message-box-hover: #fafcff;
  --message-box-border: #e9ebf0;
  --more-list-bg: #fff;
  --more-list-bg-hover: #f6fbff;
  --more-list-shadow: rgba(209, 209, 209, 0.4);
  --button-bg: #1f1c24;
  --search-area-bg: #fff;
  --star: #61eecb;
  --message-btn: #fff;
  --text-primary: #0b1944;
  --text-secondary: #ffffff;
  --shadow: rgb(216, 216, 216);
  --error: #f8a6c1;
  --accent-1: #ffd3e2;
  --accent-2: #c8f7dc;
  --accent-3: #d5deff;
  --accent-4: #fee4cb;
}

.dark:root {
  --app-container: #1f1d2b;
  --app-container: #111827;
  --main-color: #fff;
  --secondary-color: rgba(255, 255, 255, 0.8);
  --projects-section: #1f2937;
  --link-color: rgba(255, 255, 255, 0.8);
  --link-color-hover: rgba(195, 207, 244, 0.1);
  --link-color-active-bg: rgba(195, 207, 244, 0.2);
  --button-bg: #1f2937;
  --search-area-bg: #1f2937;
  --message-box-hover: #243244;
  --message-box-border: rgba(255, 255, 255, 0.1);
  --star: #ffd92c;
  --light-font: rgba(255, 255, 255, 0.8);
  --more-list-bg: #2f3142;
  --more-list-bg-hover: rgba(195, 207, 244, 0.1);
  --more-list-shadow: rgba(195, 207, 244, 0.1);
  --message-btn: rgba(195, 207, 244, 0.1);
  --text-primary: #0b1944;
  --text-secondary: #ffffff;

  --accent-1: #ffd3e2;
  --accent-2: #c8f7dc;
  --accent-3: #d5deff;
  --accent-4: #fee4cb;
}
html,
body {
  margin: 0px;
  height: 100%;
  background-color: var(--app-container);
  overflow: hidden;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--shadow);
  border-radius: 10px;
}

#app {
  font-family: "DM Sans", sans-serif;

  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--app-container);
  position: relative;
  align-items: center;

  height: 100%;
}
/* #app::-webkit-scrollbar {
  display: none;
} */

.logo {
  font-size: 35px;
  color: var(--main-color);
}
.logout-menu {
  background: var(--projects-section);
  position: absolute;

  top: 0;
  right: 10px;
  padding: 20px 30px;
  border-radius: 16px;
  color: var(--light-font);
  box-shadow: 0 2px 6px 0 rgba(136, 148, 171, 0.2),
    0 24px 20px -24px rgba(71, 82, 107, 0.1);
}
.logout-menu a {
  width: 100%;
  display: flex;
  gap: 2rem;
  color: var(--main-color);
  text-decoration: none;
}

.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 93%;
  padding: 0 2rem;
  margin-top: 2rem;
  position: relative;
}

.app-header-left,
.app-header-right {
  display: flex;
  align-items: center;
}

.app-header-right button {
  margin-left: 10px;
}
.app-icon {
  width: 26px;
  height: 2px;
  border-radius: 4px;
  background-color: var(--main-color);
  position: relative;
}
.app-icon:before,
.app-icon:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 2px;
  border-radius: 4px;
  background-color: var(--main-color);
  left: 50%;
  transform: translatex(-50%);
}
.app-icon:before {
  top: -6px;
}
.app-icon:after {
  bottom: -6px;
}
.app-name {
  color: var(--main-color);
  margin: 0;
  font-size: 35px;
  letter-spacing: 1px;
  margin: 0 32px;
}
.search-wrapper {
  border-radius: 20px;
  background-color: var(--search-area-bg);
  padding-right: 12px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 480px;
  color: var(--light-font);
  box-shadow: 0 2px 6px 0 rgba(136, 148, 171, 0.2),
    0 24px 20px -24px rgba(71, 82, 107, 0.1);
  overflow: hidden;
  margin-left: 1rem;
}
.dark .search-wrapper {
  box-shadow: none;
}
.search-input {
  border: none;
  flex: 1;
  outline: none;
  height: 100%;
  padding: 0 20px;

  font-size: 16px;
  background-color: var(--search-area-bg);
  color: var(--main-color);
}
.search-input:placeholder {
  color: var(--main-color);
  opacity: 0.6;
}
.mode-switch {
  background-color: transparent;
  border: none;
  padding: 0;
  color: var(--main-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-btn {
  color: #fff;
  background-color: var(--button-bg);
  padding: 0;
  border: 0;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.notification-btn {
  color: var(--main-color);
  padding: 0;
  border: 0;
  background-color: transparent;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0;
  border: 0;
  background-color: transparent;

  padding-left: 12px;
  border-left: 2px solid #ddd;
}
.profile-btn img {
  width: 32px;
  height: 32px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 4px;
}
.profile-btn span {
  color: var(--main-color);
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

input[type="text"],
input[type="email"] {
  width: 100%;
  max-width: 446px;
  border: 0;

  text-indent: 10px;

  outline: 0;
  background-color: transparent;
  border-bottom: 2px solid #eee;
  font-family: "DM Sans", sans-serif;
  font-size: 15px;
}
input:focus {
  border-bottom: 2px solid #dbf6fd;
}

input[type="date"] {
  width: 80%;
  max-width: 440px;
  font-size: 15px;

  text-transform: uppercase;
  font-family: "DM Sans", sans-serif;
  border: 2px solid var(--message-box-border);
  background-color: var(--app-container);
  color: var(--main-color);
  border-radius: 3px;
  padding: 0 3px;
}
input[type="number"] {
  width: 80%;
  max-width: 446px;

  height: 20px;
  border: 2px solid var(--message-box-border);
  border-radius: 8px;
  font-family: "DM Sans", sans-serif;
  font-size: 15px;

  padding: 0.25rem 0;
  outline: 0;
  text-indent: 10px;
}
textarea {
  border: 2px solid var(--message-box-border);
  width: 80%;
  max-width: 442px;

  border-radius: 8px;
  height: 4rem;
}

select {
  width: 80%;

  max-width: 450px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  font-size: 15px;
  opacity: 0.9;
  color: var(--main-color);
  border-radius: 8px;
  border: 2px solid var(--message-box-border);
}

table {
  width: 100%;
  margin: 20px auto;
  table-layout: auto;
}
table,
td,
th {
  border-collapse: collapse;
}
th,
td {
  padding: 10px;
  border: none;
  text-align: center;
}
th {
  background: var(--accent-3);

  position: sticky;
  top: 0;
  z-index: 3;
  border-collapse: collapse;
}

.error-msg {
  display: flex;
  color: var(--error);
  justify-content: center;
}
/* Large screens */
@media only screen and (min-width: 600px) {
}

/* Mid screens */
@media only screen and (max-width: 1100px) {
  .app-name {
    display: none;
  }
}
/* Small screens */
@media only screen and (max-width: 600px) {
  .app-header {
    display: none;
  }
  .search-input {
    width: 1rem;
  }

  .app-header {
    justify-content: center;
    margin-top: 10px;
  }
  .logout-menu {
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
  }
}
</style>
