<template>
  <div class="content">
    <Header @scroll-to="scrollBottom()"></Header>

    <article class="hero">
      <div class="left">
        <h1>Refrigerated Logistics Made Easy</h1>
        <span
          >Have peace of mind with our nationwide reefer logistics solutions.
          Our experienced team and specialized vehicles ensure your
          temperature-sensitive products are handled with care and delivered on
          time.</span
        >
        <div class="buttons">
          <button class="btn btn-primary" @click="scrollTo('email_bottom')">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.35156 14.4219C6.46094 17.0703 2.92969 17.0703 2.92969 17.0703C2.92969 17.0703 2.92969 13.5391 5.57812 12.6484"
                stroke="#F8F9FF"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15.3047 8.23447L10 13.5392L6.46094 10.0001L11.7656 4.6954C13.7734 2.68759 15.7813 2.71103 16.6406 2.83603C16.7732 2.85372 16.8963 2.91456 16.9909 3.00916C17.0855 3.10376 17.1464 3.22686 17.1641 3.35947C17.2891 4.21884 17.3125 6.22665 15.3047 8.23447Z"
                stroke="#F8F9FF"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14.4219 9.11719V14.1641C14.4195 14.3289 14.3521 14.4862 14.2344 14.6016L11.7109 17.1328C11.6309 17.2128 11.5306 17.2695 11.4208 17.297C11.3111 17.3244 11.1959 17.3215 11.0876 17.2886C10.9794 17.2558 10.882 17.1941 10.8061 17.1102C10.7301 17.0264 10.6783 16.9235 10.6562 16.8125L10 13.5391"
                stroke="#F8F9FF"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.8828 5.57812H5.83588C5.67102 5.58047 5.51377 5.64787 5.39838 5.76563L2.86713 8.28906C2.78716 8.36912 2.73043 8.46939 2.70299 8.57916C2.67554 8.68894 2.67842 8.8041 2.7113 8.91237C2.74418 9.02064 2.80584 9.11796 2.8897 9.19393C2.97355 9.2699 3.07646 9.32168 3.18744 9.34375L6.46088 10"
                stroke="#F8F9FF"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Get a Quote
          </button>
          <button class="btn btn-secondary">Learn More</button>
        </div>
      </div>
      <div class="right">
        <img src="@/assets/hero.png" alt="hero" />
      </div>
    </article>

    <article class="whyus">
      <h2>Why Us?</h2>
      <p>Reliable Reefer Logistics, Delivered with Confidence</p>
      <div class="cards">
        <div class="card">
          <div class="svg-box">
            <svg
              width="24"
              height="24"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 6v16l7-4 8 4 7-4V2l-7 4-8-4-7 4z"></path>
              <path d="M8 2v16"></path>
              <path d="M16 6v16"></path>
            </svg>
          </div>
          <h3>Nationwide Logistics</h3>
          <p>
            We offer nationwide temperature-controlled transport, covering every
            corner coast-to-coast, ensuring consistency in service.
          </p>
        </div>
        <div class="card">
          <div class="svg-box">
            <svg
              width="24"
              height="24"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
              ></path>
              <path d="m22 6-10 7L2 6"></path>
            </svg>
          </div>
          <h3>Personalized Reefer Van Services</h3>
          <p>
            We provide exclusive truck use for your load and no unnecessary
            stops along the route.
          </p>
        </div>
        <div class="card">
          <div class="svg-box">
            <svg
              width="24"
              height="24"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z"></path>
              <path d="M12 6v6l4 2"></path>
            </svg>
          </div>
          <h3>Round-the-Clock Operations Support</h3>
          <p>
            Our expert operations team is available 24/7 to provide
            comprehensive support for your operations.
          </p>
        </div>
      </div>
    </article>

    <article>
      <div class="right">
        <img src="@/assets/pic2.png" alt="pic2" />
      </div>

      <div class="left">
        <h2>Let's Work Together for Hassle-Free Deliveries</h2>
        <p>
          At Benefit Transportation, we specialize in temperature-controlled
          transportation and strive to provide our customers with hassle-free
          deliveries. Our state-of-the-art reefer vans, experienced team, and
          24/7 support ensure your temperature-sensitive products are handled
          with care and delivered on time.
        </p>
        <button class="btn btn-primary" @click="scrollTo('quote_bottom')">
          Get a Quote
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.125 10H16.875"
              stroke="currentColor"
              stroke-width="1.66667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M11.25 4.375L16.875 10L11.25 15.625"
              stroke-width="1.66667"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </article>

    <article>
      <div class="quote-card">
        <div class="header">
          <h2>Get a free quote</h2>
          <p>
            Fill out the form below and we will get back to you within 24 hours.
          </p>
        </div>
        <div class="quote__bottom" id="quote_bottom">
          <!-- <div class="contact">
            <div class="contact_item">
              <svg
                width="24"
                height="24"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
                ></path>
              </svg>
              888-888-8888
            </div>
            <div class="contact_item">
              <svg
                width="24"
                height="24"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
                ></path>
                <path d="m22 6-10 7L2 6"></path>
              </svg>
              info@bnfttrans.com
            </div>
          </div> -->

          <form action="https://formspree.io/f/mzbqvonq" method="POST">
            <div class="form__group">
              <input type="text" id="name" placeholder="Name" name="name" required/>
            </div>
            <div class="form__group">
              <input type="email" id="email" placeholder="Email" name="email" required/>
            </div>

            <div class="form__group">
              <textarea
                name="message"
                id="message"
                placeholder="Your message (optional) "
              ></textarea>
            </div>
            <button class="btn btn-primary" type="submit">Submit</button>
          </form>

          <img src="../assets/quote.png" alt="quote" />
        </div>
      </div>
    </article>

    <article class="footer">
      <div class="footer__item">
        <p>© 2023 Benefit Transportation. All rights reserved.</p>
      </div>

      <div class="footer__item">
        <svg
          width="24"
          height="24"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
          ></path>
          <path d="m22 6-10 7L2 6"></path>
        </svg>

        <a id="email_bottom" href="mailto:info@bnfttrans.com">
          info@bnfttrans.com
        </a>
      </div>
    </article>
  </div>
</template>

<script>
import Header from "@/components/landing_page/header.vue";
export default {
  name: "LandingPage",
  components: {
    Header,
  },
  data() {
    return {};
  },

  methods: {
    scrollTo(id) {
      console.log(id);
      const el = document.getElementById(id);
      el.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");
.content {
  width: 100%;
  height: 100%;
  padding: 0 4rem;
  background: #f8f9ff;
  font-family: "Poppins", sans-serif;

  box-sizing: border-box;
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  align-items: center;

  overflow-x: hidden;
}

.hero,
article {
  margin-top: 9rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 120px 55px;
  gap: 40px;
  max-width: 1300px;
  width: 100%;
  background: #f8f9ff;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;
  max-width: 650px;

  h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 54px;
    line-height: 130%;
    text-align: left;
    margin-bottom: 20px;
    /* or 87px */

    letter-spacing: -0.025em;

    /* Onboarding background grey */

    color: #2d2d2d;
  }

  h2 {
    all: unset;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 130%;
    /* or 65px */
    text-align: left;

    letter-spacing: -0.02em;

    /* Onboarding background grey */

    color: #2d2d2d;
  }

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 150%;
    /* or 32px */

    /* Onboarding background grey */

    color: #2d2d2d;
    text-align: left;
  }

  p {
    text-align: left;
  }
}
.right {
  img {
    width: 400px;
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  gap: 20px;
}
.btn {
  display: flex;

  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 25px;
  gap: 8px;

  border-radius: 14px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;
  font-feature-settings: "liga" off;

  &:hover {
    /*scale down the btn*/
    transform: scale(1.1);
    transition: all 0.5s ease-in-out;
  }
}

.btn-primary {
  background: #504cf8;
  border: none;

  /* White */

  color: #ffffff;
  &:hover {
    /*scale down the btn*/
    transform: scale(0.96);
    transition: all 0.3s ease-in-out;
  }
}

.btn-secondary {
  display: none !important;
  color: #504cf8;
  border: 2px solid #504cf8;

  /* White */

  background: #ffffff;

  &:hover {
    /*scale down the btn*/
    transform: scale(0.96);
    transition: all 0.3s ease-in-out;
  }
}

.whyus {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 120px 55px;
  gap: 20px;

  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 130%;
    /* or 49px */

    text-align: center;
    letter-spacing: -0.02em;

    color: #000000;
  }

  h3 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 130%;
    /* or 36px */

    text-align: center;
    letter-spacing: -0.01em;

    color: #2d2d2d;
  }
}

.cards {
  display: flex;
  flex-direction: row;
  padding: 0px;
  gap: 30px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  justify-content: center;
}

.card {
  /* Auto layout */

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 40px 30px;
  gap: 10px;

  width: 30%;

  background: #ffffff;

  border: 1px solid #e5f4f2;
  box-shadow: 34.8541px 29.626px 48.34px rgba(51, 102, 255, 0.05);
  border-radius: 20px;

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */

    text-align: center;

    color: #2d2d2d;
  }
}

.svg-box {
  padding: 20px;
  background: rgba(0, 147, 121, 0.2);
  border-radius: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(80, 76, 248, 0.1);
  border-radius: 10px;

  svg {
    stroke: #504cf8;
    width: 42px;
    height: 42px;
  }
}

.quote-card {
  /* Contact Form */

  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 60px;
  gap: 60px;

  background: #ffffff;
  /* CTA - Aqua Squeeze

Secondary CTAs & CTA backgrounds
*/
  border: 1px solid #e5f4f2;
  border-radius: 20px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
}

.header {
  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 130%;
    /* or 49px */

    letter-spacing: -0.02em;

    /* Onboarding background grey */

    color: #2d2d2d;
    text-align: left;
    margin: 10px 0;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */

    /* Onboarding background grey */

    color: #2d2d2d;
  }
}

.quote__bottom {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;
  width: 100%;

  img {
    width: 250px;
  }
}
.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  .contact_item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    width: 100%;
    padding: 0px 20px;
    svg {
      stroke: #504cf8;
      width: 24px;
      height: 24px;
    }
    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      /* or 24px */

      text-align: center;

      color: #2d2d2d;
    }
  }
}

form {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  .form__group {
    display: flex;
    width: 100%;
    input {
      width: 100%;
      max-width: 400px;
      box-shadow: none !important;
      border: 1px solid #d8d8d8;
      border-radius: 10px !important;
      height: 50px;

      background: #ffffff;

      border-radius: 20px;
    }

    textarea {
      width: 100%;
      max-width: 400px;
      box-shadow: none !important;
      border: 1px solid #d8d8d8;
      border-radius: 10px !important;
      height: 100px;

      background: #ffffff;

      border-radius: 20px;
    }
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 0px 20px;

  width: 100%;

  .footer__item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;

    svg {
      stroke: #2d2d2d;
      width: 24px;
      height: 24px;
    }
    a {
      all: unset;
      cursor: pointer;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 160%;
      /* or 22px */

      color: #2d2d2d;
    }
  }
}
/* Large screens */
@media only screen and (min-width: 600px) {
}

/* Mid screens */
@media only screen and (max-width: 1100px) {
  .content {
    padding: 0px 20px;
  }

  .hero,
  article {
    flex-direction: column;
    margin: 1rem 0;
  }
  .left {
    gap: 40px;
    align-items: center;
    h1 {
      font-size: 46px;
      margin: 10px 0;
      text-align: center;
    }
    h2 {
      font-size: 38px;
      text-align: center;
    }
    span,
    p {
      font-size: 18px;
      text-align: center;
    }
    img {
      width: 320px;
      height: auto;
    }
  }

  .right {
    img {
      width: 220px;
      height: 220px;
    }
  }

  .buttons {
    width: 100%;
    justify-content: center;
  }

  .btn-secondary {
    display: none;
  }

  .btn {
    padding: 18px 50px;
  }

  .cards {
    flex-direction: column;
    align-items: center;
  }

  .card {
    width: 90%;
  }

  .whyus {
    padding: 50px 20px;
  }

  .quote-card {
    gap: 10px;
  }

  .header {
    h2 {
      font-size: 40px;
      text-align: center;
    }
  }

  .quote__bottom {
    flex-direction: column;
    align-items: center;

    form {
      width: 100%;

      .form__group {
        width: 100%;

        input {
          width: 100%;
          max-width: 100%;
        }

        textarea {
          width: 100%;
          max-width: 100%;
        }
      }
    }

    img {
      display: none;
    }
  }
}

/* Small screens */
@media only screen and (max-width: 600px) {
  .hero {
    padding: 50px 20px;
    margin: 8rem 0;
    gap: 8rem;
  }
  .left {
    h1 {
      font-size: 40px;

      text-align: center;
      letter-spacing: -0.025em;
    }
    span {
      font-size: 16px;
      text-align: center;
    }
  }

  .btn-primary {
    padding: 15px 25px;
  }

  .quote-card {
    padding: 20px 20px;
  }
}
</style>
