<template>
  <div
    class="add-trip-wrap"
    :class="this.$store.state.view_add_trip ? 'show' : ''"
  >
    <div class="header">
      <!-- <div id="title">Add a Trip</div> -->
      <div class="close-btn" @click="toggleMobileView()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-x-circle"
        >
          <circle cx="12" cy="12" r="10"></circle>
          <line x1="15" y1="9" x2="9" y2="15"></line>
          <line x1="9" y1="9" x2="15" y2="15"></line>
        </svg>
      </div>
    </div>

    <div v-if="!summary_visible && !calculating" class="content">
      <div class="section">
        <div id="title">Load Type</div>

        <div class="select-load-form">
          <select v-model="trip_type">
            <option value="Reefer">Reefer</option>
            <option value="Dry">Dry</option>
            <option value="Layover">Layover</option>
            <option value="Cancel">Cancel</option>
            <option value="Detention">Detention</option>
            <option value="Empty Miles">Empty Miles</option>
            <option value="Other">Other</option>
          </select>
        </div>
      </div>

      <div class="section">
        <div id="title">Company Name</div>
        <div class="form">
          <input
            v-model="company_name"
            placeholder="Company name"
            type="text"
          />
        </div>
      </div>

      <div class="section">
        <div id="title">Date</div>
        <input type="date" v-model="trip_date" />
      </div>

      <div
        class="section"
        v-if="
          trip_type == 'Layover' ||
          trip_type == 'Cancel' ||
          trip_type == 'Detention' ||
          trip_type == 'Other'
        "
      >
        <div id="title">Driver Payment*</div>
        <input type="number" v-model="total_payment" />
      </div>

      <div
        class="section"
        v-if="
          trip_type != 'Layover' &&
          trip_type != 'Cancel' &&
          trip_type != 'Detention' &&
          trip_type != 'Other'
        "
      >
        <div id="title">Start Location*</div>

        <div class="form">
          <input
            ref="start_autocomplete"
            :placeholder="start_location"
            class="search-location"
            type="text"
          />
        </div>
      </div>

      <div
        class="section"
        v-if="
          trip_type != 'Layover' &&
          trip_type != 'Cancel' &&
          trip_type != 'Detention' &&
          trip_type != 'Other'
        "
      >
        <div id="title">Pickup Location*</div>

        <div class="form">
          <input
            ref="pickup_autocomplete"
            :placeholder="pick_up_location"
            class="search-location"
            type="text"
          />
        </div>
      </div>

      <div
        class="section"
        v-if="
          trip_type != 'Layover' &&
          trip_type != 'Cancel' &&
          trip_type != 'Empty Miles' &&
          trip_type != 'Detention' &&
          trip_type != 'Other'
        "
      >
        <div id="title">Delivery Location*</div>

        <div class="form">
          <input
            ref="delivery_autocomplete"
            :placeholder="delivery_location"
            class="search-location"
            type="text"
          />
        </div>
      </div>

      <div class="section" v-if="trip_type != 'Other'">
        <div id="title">Load Price</div>

        <input
          placeholder="$"
          class="search-location"
          type="number"
          v-model="gross_income"
        />
      </div>

      <div class="section">
        <div id="title">Additional Notes</div>

        <textarea v-model="additional_notes" />
      </div>

      <div
        @click="additional_info_visible = !additional_info_visible"
        class="checkbox-wrap"
      >
        <div v-if="!additional_info_visible" class="checkbox">
          Show additional information
        </div>
        <div v-else class="checkbox">Hide additional information</div>
      </div>
      <div v-if="additional_info_visible" class="additional-info">
        <div id="item">
          Empty Miles Rate <input type="number" v-model="empty_miles_rate" />
        </div>
        <div id="item">
          Driver's Rate
          <input type="number" v-model="driver_rate" />
        </div>
        <div id="item">
          Insurance Cost
          <input type="number" v-model="insurance_cost" />
        </div>

        <div id="item">
          Fuel Expenses
          <input type="number" v-model="fuel_expenses" />
        </div>
      </div>

      <div class="submit-wrap">
        <div @click="calculate()" class="submit-btn">Calculate</div>
      </div>
    </div>

    <div v-if="calculating">Loading</div>

    <div v-if="summary_visible && !calculating" class="summary">
      <h1>Summary</h1>
      <div class="summary-item">
        <div id="name" class="warning">
          {{ low_miles_warning }}
        </div>
      </div>
      <div class="summary-item">
        <div id="name">Trip type:</div>
        <div id="info">{{ trip_type }}</div>
      </div>
      <div class="summary-item">
        <div id="name">Company Name:</div>
        <div id="info">{{ company_name }}</div>
      </div>
      <div class="summary-item">
        <div id="name">Start Address:</div>
        <div id="info">{{ start_location }}</div>
      </div>
      <div class="summary-item">
        <div id="name">Pickup Address:</div>
        <div id="info">{{ pick_up_location }}</div>
      </div>
      <div class="summary-item">
        <div id="name">Delivery Address:</div>
        <div id="info">{{ delivery_location }}</div>
      </div>

      <div class="summary-item">
        <div id="name">Empty Miles:</div>
        <div id="info">{{ empty_miles }}</div>
      </div>
      <div class="summary-item">
        <div id="name">Empty Miles Payment:</div>
        <div id="info">{{ empty_miles_payment }}</div>
      </div>
      <div class="summary-item">
        <div id="name">Loaded Miles:</div>
        <div id="info">{{ loaded_miles }}</div>
      </div>
      <div class="summary-item">
        <div id="name">Loaded Miles Payment:</div>
        <div id="info">{{ loaded_miles_payment }}</div>
      </div>
      <div class="summary-item">
        <div id="name">Total Driver Payment:</div>
        <div id="info">{{ total_payment }}</div>
      </div>
      <div class="summary-item">
        <div id="name">Gross Income:</div>
        <div id="info">{{ gross_income }}</div>
      </div>
      <div class="summary-item">
        <div id="name">Net Income:</div>
        <div id="info">{{ net_income }}</div>
      </div>

      <div class="secondary-info">
        <div>Driver Rate: ${{ computed_driver_rate }} / mile</div>
        <div>Trip Date: {{ trip_date }}</div>
        <div>Empty Miles Rate: {{ empty_miles_rate }}</div>
      </div>
      <div class="submit-wrap" ref="scrollToSubmit">
        <div @click="addTrip()" class="submit-btn">Submit</div>
        <div class="error-msg">{{ error_msg }}</div>
      </div>
      <div class="back-btn" @click="backToAddMenu()">Back</div>
    </div>
  </div>
</template>

<script>
import { /*  doc, setDoc,  */ Timestamp } from "firebase/firestore";
import { collection, addDoc } from "firebase/firestore";
import { db, auth } from "@/main";

export default {
  data() {
    return {
      start_location: null,
      insurance_cost: null,
      company_name: null,
      delivery_date: null,
      delivery_location: null,
      driver_rate: null,
      empty_miles: null,
      empty_miles_payment: null,
      empty_miles_rate: 0.3,
      fuel_expenses: null,
      gross_income: 0,
      loaded_miles: null,
      loaded_miles_payment: null,
      net_income: null,
      net_income_after_fuel: null,
      payment_posted: false,
      pick_up_date: null,
      pick_up_location: null,
      total_payment: null,
      trip_date: new Date().toJSON().slice(0, 10).replace(/-/g, "-"),
      trip_number: null,
      trip_type: "Reefer",
      additional_notes: "",

      additional_info_visible: false,
      error_msg: "",

      summary_visible: false,
      calculating: false,
    };
  },
  methods: {
    toggleMobileView() {
      this.$store.state.view_add_trip = !this.$store.state.view_add_trip;
    },
    backToAddMenu() {
      this.summary_visible = false;
      this.calculating = false;
    },

    async calculate() {
      this.calculating = true;

      if (
        this.trip_type == "Layover" ||
        this.trip_type == "Cancel" ||
        this.trip_type == "Detention" ||
        this.trip_type == "Other"
      ) {
        this.net_income = this.gross_income - this.total_payment;
        this.calculating = false;
        this.summary_visible = true;

        return;
      }

      if (this.trip_type == "Empty Miles") {
        this.delivery_location = this.$refs.pickup_autocomplete.value;
      } else {
        this.delivery_location = this.$refs.delivery_autocomplete.value;
      }

      this.start_location = this.$refs.start_autocomplete.value;

      this.pick_up_location = this.$refs.pickup_autocomplete.value;

      try {
        const service = new google.maps.DistanceMatrixService();
        const request = {
          origins: [this.start_location],
          destinations: [this.pick_up_location],
          travelMode: google.maps.TravelMode.DRIVING,
          unitSystem: google.maps.UnitSystem.IMPERIAL,
          avoidHighways: false,
          avoidTolls: true,
        };

        let response = await service.getDistanceMatrix(request);

        this.empty_miles = this.round(
          response.rows[0].elements[0].distance.value * 0.000621371192
        );

        let loaded_request = {
          origins: [this.pick_up_location],
          destinations: [this.delivery_location],
          travelMode: google.maps.TravelMode.DRIVING,
          unitSystem: google.maps.UnitSystem.IMPERIAL,
          avoidHighways: false,
          avoidTolls: true,
        };

        let loaded_response = await service.getDistanceMatrix(loaded_request);

        this.loaded_miles = this.round(
          loaded_response.rows[0].elements[0].distance.value * 0.000621371192
        );

        this.loaded_miles_payment = this.round(
          this.loaded_miles * this.computed_driver_rate
        );

        this.empty_miles_payment = this.round(
          this.empty_miles * this.empty_miles_rate
        );

        this.total_payment = this.round(
          this.loaded_miles_payment + this.empty_miles_payment
        );

        this.net_income = this.gross_income - this.total_payment;
      } catch (error) {
        console.log(error);
      }
      this.calculating = false;
      this.summary_visible = true;
    },
    round(num) {
      num = Math.round(num * 100) / 100;
      return num;
    },
    async addTrip() {
      if (!this.$store.state.selected_driver.id ) {
        this.error_msg =
          "Add a driver before you add any trips.";
        return;
      }
      this.error_msg = "";
      if (
        !this.trip_type &&
        !this.total_payment &&
        !this.gross_income &&
        !this.net_income
      ) {
        this.error_msg =
          "Please make sure to fill out all of the required fields.";
        return;
      }
      try {
        const docRef = await addDoc(
          collection(
            db,
            `users/${auth.currentUser.uid}/drivers/${this.$store.state.selected_driver.id}/trips`
          ),
          {
            start_location: this.start_location,
            insurance_cost: this.insurance_cost,
            company_name: this.company_name,
            delivery_date: this.delivery_date,
            delivery_location: this.delivery_location,
            driver_rate: this.computed_driver_rate,
            empty_miles: this.empty_miles,
            empty_miles_payment: this.empty_miles_payment,
            empty_miles_rate: 0.3,
            fuel_expenses: this.fuel_expenses,
            gross_income: this.gross_income,
            loaded_miles: this.loaded_miles,
            loaded_miles_payment: this.loaded_miles_payment,
            net_income: this.net_income,
            net_income_after_fuel: this.net_income_after_fuel,
            payment_posted: this.payment_posted,
            pick_up_date: this.pick_up_date,
            pick_up_location: this.pick_up_location,
            start_location: this.start_location,
            total_payment: this.total_payment,
            trip_date: Timestamp.fromDate(
              new Date(new Date(this.trip_date).setHours(24, 0, 0, 0))
            ),

            trip_type: this.trip_type,
            additional_notes: this.additional_notes,
          }
        );
        console.log("Document written with ID: ", docRef.id + this.trip_date);
      } catch (error) {
        console.log(error);
      }
      this.backToAddMenu();
      this.resetData();
    },
    resetData() {
      if (
        this.trip_type != "Layover" &&
        this.trip_type != "Cancel" &&
        this.trip_type != "Detention" &&
        this.trip_type != "Other"
      ) {
        this.$refs.start_autocomplete.value = "";
        this.$refs.pickup_autocomplete.value = "";
        this.$refs.delivery_autocomplete.value = "";
      }

      this.start_location = null;
      this.insurance_cost = null;
      this.company_name = null;
      this.delivery_date = null;
      this.delivery_location = null;
      this.driver_rate = null;
      this.empty_miles = null;
      this.empty_miles_payment = null;

      this.empty_miles_rate = 0.3;
      this.fuel_expenses = null;
      this.gross_income = null;
      this.loaded_miles = null;
      this.loaded_miles_payment = null;
      this.net_income = null;
      this.net_income_after_fuel = null;
      this.payment_posted = null;
      this.pick_up_date = null;
      this.pick_up_location = null;
      this.start_location = null;
      this.total_payment = null;
      this.trip_date = new Date().toJSON().slice(0, 10).replace(/-/g, "-");
      this.trip_number = null;
      this.trip_type = null;
      (this.additional_notes = ""), this.toggleMobileView();
    },
  },
  mounted() {
    this.start_autocomplete = new google.maps.places.Autocomplete(
      this.$refs.start_autocomplete,
      { types: ["address"] }
    );
    this.pickup_autocomplete = new google.maps.places.Autocomplete(
      this.$refs.pickup_autocomplete,
      { types: ["address"] }
    );
    this.delivery_autocomplete = new google.maps.places.Autocomplete(
      this.$refs.delivery_autocomplete,
      { types: ["address"] }
    );
    /* this.query = this.autocomplete.getPlace();
    console.log(this.query); */
  },
  computed: {
    computed_driver_rate: function () {
      if (this.driver_rate == null) {
        switch (this.trip_type) {
          case "Reefer":
            return 0.45;

          case "Dry":
            return 0.35;

          default:
            return 0;
        }
      } else {
        return this.driver_rate;
      }
    },
    low_miles_warning: function () {
      if (this.empty_miles + this.loaded_miles < 1000)
        return "[ Warning ] : Miles are below 1000.";
      return "";
    },
  },
};
</script>

<style scoped>
.warning,
.error-msg {
  color: #fa6d9c;
}
.add-trip-wrap {
  background-color: var(--projects-section);
  flex: 1;
  /* width: 100%; */
  border-radius: 32px;
  position: relative;
  padding: 32px 32px;
  padding-top: 2rem;
  height: 80vh;
  overflow: scroll;
  transition: all 2ms cubic-bezier(0.19, 1, 0.56, 1);
  -ms-overflow-style: none;
  scrollbar-width: none;
  z-index: 10;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
}
.add-trip-wrap::-webkit-scrollbar {
  display: none;
}
.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.close-btn {
  display: none;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#title {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding: 22px 10px;
  opacity: 0.9;
  color: var(--main-color);
  justify-content: center;
}
.section #title {
  width: 100%;
  padding: 10px 10px;
  margin-left: 2rem;

  color: var(--main-color);
  text-align: left;
  font-size: 19px;
}
.select-load-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.select-load-form select {
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  font-size: 15px;
  opacity: 0.9;
  color: var(--main-color);

  border-radius: 3px;
  border: 2px solid var(--message-box-border);
}

/* input[type="text"] {
  width: 100%;

  border: 0;

  text-indent: 10px;

  outline: 0;
  background-color: transparent;
  border-bottom: 2px solid #eee;
  font-family: "DM Sans", sans-serif;
  font-size: 15px;
}
input[type="text"]:focus {
  border-bottom: 2px solid #dbf6fd;
}

input[type="date"] {
  margin: 0 auto;
  width: 80%;

  font-size: 15px;

  text-transform: uppercase;
  font-family: "DM Sans", sans-serif;
  border: 2px solid var(--message-box-border);
  background-color: var(--app-container);
  color: var(--main-color);
  border-radius: 3px;
  padding: 0 3px;
}
input[type="number"] {
  width: 80%;
  height: 20px;
  border: 2px solid var(--message-box-border);
  border-radius: 8px;
  font-family: "DM Sans", sans-serif;
  font-size: 15px;

  padding: 0.25rem 0;
  outline: 0;
  text-indent: 10px;
}
textarea {
  border: 2px solid var(--message-box-border);
  width: 80%;
  max-width: 100%;
  border-radius: 8px;
  height: 4rem;
} */
.form {
  width: 85%;
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: space-around;
  gap: 5px;
}
.confirm-btn {
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 15px;
  background-color: #dbf6fd;
  cursor: pointer;
}
.confirm-btn:hover {
  background-color: #c1dee6;
}

.back-btn {
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 15px;
  background-color: var(--shadow);
  cursor: pointer;
}
.back-btn:hover {
  background-color: rgb(173, 173, 173);
}
.additional-info {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  color: var(--secondary-color);
}
.additional-info #item {
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}
.submit-wrap {
  margin: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.submit-btn {
  padding: 10px 40px;
  background-color: #ffd3e2;
  border-radius: 10px;
  width: 4rem;
}
.submit-btn:hover {
  background-color: #ff80a8;
}
.checkbox-wrap {
  margin-top: 2rem;
  color: var(--secondary-color);
  cursor: pointer;
}
.checkbox:hover {
  color: #000;
}

.summary {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 6rem;
}
.summary h1 {
  font-weight: 100;
}
.summary-item {
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.summary-item #name {
  font-size: 18px;
}
.summary-item #info {
  font-weight: bold;
  color: var(--main-color);
}
.secondary-info {
  margin-top: 2rem;
  color: var(--main-color);
  font-size: 14px;
  opacity: 0.6;
}
/* Large screens */
@media only screen and (min-width: 600px) {
}

/* Mid screens */
@media only screen and (max-width: 1100px) {
  .add-trip-wrap {
    transform: translateX(100%);
    position: fixed;
    opacity: 0;
    top: 0;

    padding-bottom: 4rem;
    width: 100%;
    height: 100%;
    overflow: scroll;
  }
  .content {
    margin-bottom: 4rem;
  }
  .show {
    transform: translateX(0);
    opacity: 1;
    margin: 0;
  }
  .close-btn {
    display: flex;

    justify-content: flex-end;
    margin-right: 1rem;
  }
  .close-btn svg {
    width: 30px;
  }

  .section #title {
    margin-left: 2.5rem;
  }
}
/* Small screens */
@media only screen and (max-width: 600px) {
  #title {
    font-size: 35px;
  }
  .submit-btn:hover {
    background-color: #ff80a8;
  }
}
</style>
