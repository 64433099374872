<template>
  <div class="content">
    <Sidebar />

    <Dashboard />

    <AddTrip v-if="add_trip_visible" />
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from "../components/sidebar.vue";
import Dashboard from "../components/dashboard.vue";
import AddTrip from "../components/add-trip.vue";

export default {
  name: "DashboardView",
  components: { Sidebar, Dashboard, AddTrip },
  data() {
    return {
      add_trip_visible: true,
    };
  },
  methods: {
    toggleCalculator() {
      this.add_trip_visible = !this.add_trip_visible;
    },
  },
};
</script>

<style scoped>
.content {
  margin-top: 2rem;
  width: 99%;
  max-width: 1800px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  gap: 10px;
}
.sidebar-wrap {
  width: 16rem;
  height: 100vh;
}

/* Large screens */
@media only screen and (min-width: 600px) {
}

/* Mid screens */
@media only screen and (max-width: 1100px) {
  .sidebar-wrap {
    width: 5rem;
  }
}
/* Small screens */
@media only screen and (max-width: 600px) {
  .sidebar-wrap {
    width: 0px;
  }
  .content {
    gap: 0rem;
    margin-top: 10px;
  }
}
</style>
