import { createRouter, createWebHistory } from "vue-router";
import LandingPage from "../views/LandingPage";
import LoginPage from "../views/LoginView";
import DriversPage from "../views/DriversView.vue";

import HomePage from "../views/HomeView.vue";

import DashboardPage from "../views/DashboardView.vue";
import PayrollPage from "../views/PayrollView.vue";
import MasterPage from "../views/MasterView";
import TeamPage from "../views/TeamView";



import { getAuth } from "firebase/auth";

const routes = [
  {
    path: "/",
    name: "LandingPage",
    component: LandingPage,
  },
  {
    path: "/login",
    name: "LoginView",
    component: LoginPage,
  },
  {
    path: "/home",
    name: "home",

    component: HomePage,
    meta: { requiresAuth: true }
  },
  {
    path: "/drivers",
    name: "drivers",

    component: DriversPage,
    meta: { requiresAuth: true }
  },
  {
    path: "/dashboard",
    name: "dashboard",

    component: DashboardPage,
    meta: { requiresAuth: true }
  },
  {
    path: "/payroll",
    name: "Payroll",

    component: PayrollPage,
    meta: { requiresAuth: true }
  },
  {
    path: "/master",
    name: "Master",

    component: MasterPage,
    meta: { requiresAuth: true }
  }, {
    path: "/team",
    name: "Team",

    component: TeamPage,
    meta: { requiresAuth: true }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = getAuth().currentUser;

  if (requiresAuth && !isAuthenticated) {
    next("/");
  } else {
    next();
  }

})
export default router;
