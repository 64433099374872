import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// Import the functions you need from the SDKs you need
//import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from 'firebase/functions';
import { getAuth, onAuthStateChanged } from "firebase/auth";
const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");
import firebase from 'firebase/compat/app';





const firebaseConfig = {
    apiKey: "AIzaSyBn4eHEkpu2NcLCwQ3mbK_ZN0xgPRIC0H4",
    authDomain: "benefittrans-f20ac.firebaseapp.com",
    projectId: "benefittrans-f20ac",
    storageBucket: "benefittrans-f20ac.appspot.com",
    messagingSenderId: "561893097408",
    appId: "1:561893097408:web:3d23c167cf913907105225",
    measurementId: "G-Q5RYJ1CDK4"
};
if (process.env.NODE_ENV == 'development') {

    window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;

}

const app = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const auth = getAuth(app);
const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6LfWlIcfAAAAAHvbal2pyaXHH9jD2MH-7GvtPvGU'),

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true
});


let vue_app;

onAuthStateChanged(auth, (user) => {
    if (!vue_app) {
       


        vue_app = createApp(App).use(store).use(router).mount("#app");


    }
})

export { app, db, analytics, functions, auth, firebase, appCheck };

