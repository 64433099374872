<template>
  <div class="content-wrap">
    <div class="sidebar-wrap"><Sidebar /></div>
    <div class="home">
      <div class="content">
        <div class="title">
          <p>Home</p>
          
        </div>
        <div class="dashboard-stats">
          <div class="stats-item">
            <div id="number">
              {{ unpaid_trips }}
            </div>
            <div id="name">Unpaid Trips</div>
          </div>
          <div class="stats-item">
            <div id="number">${{ gross_income.toLocaleString("en-US") }}</div>
            <div id="name">Gross Income</div>
          </div>
          <div class="stats-item">
            <div id="number">
              ${{ Math.round(net_income).toLocaleString("en-US") }}
            </div>
            <div id="name">Net Income</div>
          </div>
          <div class="stats-item">
            <div id="number">
              {{ total_loaded_miles.toLocaleString("en-US") }} mi
            </div>
            <div id="name">Total Loaded Miles</div>
          </div>
        </div>
        <div class="chart"></div>
      </div>
    </div>
  </div>
</template>

<script >
// @ is an alias to /src
import Sidebar from "../components/sidebar.vue";
import Dashboard from "../components/dashboard.vue";

import { db, auth } from "@/main";
import { collection, getDocs, addDoc  } from "@firebase/firestore";
export default {
  name: "DashView",
  components: { Sidebar, Dashboard },
  data() {
    return {
      field1: null,
    };
  },

  methods: {
    /* async rewrite() {
      if (!auth.currentUser) return;
      //get a collection from Firestore
      const querySnapshot = await getDocs(
        collection(db, "drivers/ZKtv2KduxtSLjVgZKCg8/trips")
      );
      querySnapshot.forEach(async(doc) => {
        await addDoc(
          collection(
            db,
            `users/UnS9KxEZWqRJZg0OqtAbZJ5oAEq2/drivers/ZKtv2KduxtSLjVgZKCg8/trips`
          ),
          {
           doc
          }
        );

      });
    }, */
  },

  computed: {
    // a computed getter
    unpaid_trips: function () {
      let paid = this.$store.state.trips.filter(
        (item) =>
          !item.payment_posted &&
          item.trip_type != "Cancel" &&
          item.trip_type != "Layover"
      );
      return `${paid.length}/${this.$store.state.trips.length}`;
    },
    total_loaded_miles: function () {
      let total = 0;
      this.$store.state.trips.forEach((element) => {
        total += element.loaded_miles;
      });

      return total;
    },
    gross_income: function () {
      let total = 0;
      this.$store.state.trips.forEach((element) => {
        total += element.gross_income;
      });

      return total;
    },
    net_income: function () {
      let total = 0;
      this.$store.state.trips.forEach((element) => {
        total += element.net_income;
      });

      return total;
    },
  },
};
</script>

<style scoped>
.content-wrap {
  margin-top: 2rem;
  width: 100%;
  max-width: 1800px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.sidebar-wrap {
  position: relative;
  width: 20rem;
  height: 100%;
}
.content {
  display: flex;
  flex-direction: column;

  align-items: center;

  height: 25rem;
  background-color: var(--projects-section);
  border-radius: 32px;
  padding: 32px 32px;
  overflow: hidden;

  gap: 1rem;
}
.home {
  width: 100%;
  padding: 0 1rem;
}
.title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  color: var(--main-color);
}
.title p {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  opacity: 0.9;
  margin: 0;
  color: var(--main-color);
}
.glance-cards {
  width: 100%;
  display: flex;
  margin-top: 2rem;
}
.card {
  display: flex;
  padding: 10px 10px;

  border-radius: 8px;
  gap: 1rem;
  align-items: center;
}
.card-title {
  font-size: 15px;
  color: var(--main-color);
}
.icon {
  width: 1rem;
}
.data {
  display: flex;
  flex-direction: column;
}
.numbers {
  display: flex;
  align-items: center;
  font-weight: bold;
  gap: 4px;
}
#big-num {
  font-size: 24px;
}
#small-num {
}

.dashboard-stats {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 16px;
  margin-top: 2rem;
  width: 100%;
}

.stats-item {
  display: flex;
  flex-direction: column;

  align-items: flex-start;
}

#name {
  position: relative;
  padding-right: 24px;
  color: var(--secondary-color);
}
#number {
  font-size: 18px;
  line-height: 32px;
  font-weight: 700;
  color: var(--main-color);
}

.chart {
  width: 100%;
}

/* Large screens */
@media only screen and (min-width: 600px) {
}

/* Mid screens */
@media only screen and (max-width: 1100px) {
  .sidebar-wrap {
    width: 6rem;
  }
}
/* Small screens */
@media only screen and (max-width: 600px) {
  .sidebar-wrap {
    width: 0px;
  }
  .content {
    padding: 15px 20px;
  }
}
</style>
