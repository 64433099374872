
import { createStore } from "vuex";

export default createStore({
  state: {
    view_add_trip: false,
    trips: [],
    drivers: [],
    selected_driver: {}, 
    selected_trip: 1, 

  },

  getters: {},

  mutations: {
    setTrips(state, item) {
      state.trips.push(item);
      state.trips.sort((a, b) => {
        return new Date(a.trip_date.toDate()) - new Date(b.trip_date.toDate());
      });
      state.trips.reverse();
    },

    setDrivers(state, item) {
      state.drivers.push(item);
    },
    setSelectedDriver(state, item) {
      state.selected_driver = {
        ...item
      };
    },
    setSelectedTrip(state, item) {
      state.selected_trip = item;
    }


  },
  actions: {
    addTrip({ commit }, payload) {
      commit("setTrips", payload);
    },

    addDriver({ commit }, payload) {
      commit("setDrivers", payload);
    },

    addSelectedDriver({ commit }, payload) {
      
      commit("setSelectedDriver", payload);
    },
    selectTrip({ commit }, payload) {
      commit("setSelectedTrip", payload);
    },
  },
  modules: {},
});
