<template>
  <div class="content">
    <Sidebar />

    <div class="payroll">
      <div class="title">
        <p>Payroll</p>
      </div>
      <div class="controls">
        <div class="controls-item" @click="generatePDF()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-download"
          >
            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
            <polyline points="7 10 12 15 17 10"></polyline>
            <line x1="12" y1="15" x2="12" y2="3"></line>
          </svg>
        </div>
        <div class="filters">
          <div class="filter">
            <div id="title">Start Date</div>
            <input type="date" v-model="start_date" />
          </div>
          <div class="filter">
            <div id="title">End Date</div>
            <input type="date" v-model="end_date" />
          </div>
        </div>
      </div>

      <div ref="pdf_report" class="reports" v-if="start_date && end_date">
        <div id="master">
          <table>
            <tr>
              <th>Trip Type</th>
              <th>Company</th>
              <th>Date</th>

              <th>Pick Up Location</th>

              <th>Delivery Location</th>
              <th>Payment</th>
              <th>Notes</th>
            </tr>
            <ReportCard
              v-for="trip in filteredTrips"
              :key="trip.id"
              :delivery_location="trip.delivery_location"
              :pick_up_location="trip.pick_up_location"
              :trip_date="trip.trip_date"
              :trip_type="trip.trip_type"
              :total_payment="trip.total_payment"
              :company_name="trip.company_name"
              :additional_notes="trip.additional_notes"
            />
          </table>
        </div>

        <div class="total">Total: ${{ computed_total_payment }}</div>
        <div>
          {{ filteredTrips.length }} trips since {{ toTextDate(start_date) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from "@/components/sidebar.vue";
import ReportCard from "@/components/report-card.vue";
import html2pdf from "html2pdf.js";

//import { jsPDF } from "jspdf";

export default {
  name: "DashView",
  components: { Sidebar, ReportCard },
  data() {
    return {
      start_date: null,
      end_date: new Date().toJSON().slice(0, 10).replace(/-/g, "-"),
    };
  },
  methods: {
    generatePDF() {
      let opt = {
        margin: [10, 5,10, 5], //top, left, buttom, right
        filename: "Payroll.pdf",
        image: { type: "png", quality: 4 },
        html2canvas: {  scale: 5, letterRendering: true },
        jsPDF: { unit: "mm", format: "letter", orientation: "portrait" },
        pageBreak: { mode: "css", after: ".break-page" },
      };
      html2pdf().set(opt).from(this.$refs.pdf_report).toPdf().get("pdf").save();
    },
    toTextDate(date) {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const d = new Date(date);
      return `${monthNames[d.getMonth()]} ${d.getDate()}`;
    },
  },
  computed: {
    filteredTrips: function () {
      let trips_between_dates = this.$store.state.trips.filter(
        (item) =>
          new Date(item.trip_date.toDate()) > new Date(this.start_date) &&
          new Date(item.trip_date.toDate()) < new Date(this.end_date)
      );

      console.log(trips_between_dates);
      return trips_between_dates;
    },
    computed_total_payment: function () {
      let num = this.filteredTrips.reduce((accumulator, object) => {
        return accumulator + object.total_payment;
      }, 0);
      console.log(num);
      num = Math.round(num * 100) / 100;
      console.log(num);

      return num;
    },
  },
  created() {
    const t = new Date().getDate() + (6 - new Date().getDay() - 1) - 7;
    const lastFriday = new Date();
    lastFriday.setDate(t);
    this.start_date = new Date(lastFriday)
      .toJSON()
      .slice(0, 10)
      .replace(/-/g, "-");
  },
};
</script>

<style scoped>
.content {
  margin-top: 2rem;
  width: 99%;
  max-width: 1800px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}
.payroll {
  flex: 2;
  background-color: var(--projects-section);
  border-radius: 32px;
  padding: 32px 32px;
  width: 95%;
  height: 80vh;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
}
.title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.title p {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  opacity: 0.9;
  margin: 0;
  color: var(--main-color);
}
.controls {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  margin: 10px 0;
}
.controls-item {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  border-radius: 4px;
  background-color: transparent;
  border: none;
  color: var(--main-color);
  margin-left: 8px;
  transition: 0.2s;
}

.controls-item:hover {
  background-color: var(--shadow);
}
.filters {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.report-header {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  min-height: 2rem;
  font-size: 16px;

  border-radius: 16px 16px 0 0;
  background: #e9e7fd;
}
.header-item {
  width: 10rem;
}
.reports {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}
#master {
  overflow: scroll;
  width: 100%;
  /* max-height: 20rem; */
}

.total {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  margin-top: 1rem;
}

.submit-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

/* Large screens */
@media only screen and (min-width: 600px) {
}

/* Mid screens */
@media only screen and (max-width: 1100px) {
  .sidebar-wrap {
    width: 5rem;
  }
}
/* Small screens */
@media only screen and (max-width: 600px) {
  .content {
    gap: 0rem;
    margin-top: 10px;
  }
  .payroll {
    padding: 32px 20px;
  }

  .title p {
    font-size: 35px;
  }
  
}
</style>
