<template>
  <tr class="trip-row">
    <td>{{ trip_type }}</td>

    <td v-if="company_name">{{ company_name }}</td>
    <td v-else>-</td>

    <td>
      {{
        trip_date
          .toDate()
          .toDateString()
          .replace(/^\S+\s/, "")
      }}
    </td>

    <td v-if="pick_up_location">{{ short_pickup_location }}</td>
    <td v-else>-</td>

    <td v-if="delivery_location">{{ short_delivery_location }}</td>
    <td v-else>-</td>

    <td v-if="total_payment">{{ total_payment }}</td>
    <td v-else>-</td>

    <td v-if="additional_notes">{{ additional_notes }}</td>
    <td v-else>-</td>
  </tr>
</template>

<script>
export default {
  props: [
    "trip_type",
    "trip_date",
    "pick_up_location",
    "delivery_location",
    "total_payment",
    "company_name",
    "additional_notes",
  ],
  computed: {
    
    short_pickup_location: function () {
      let a = this.pick_up_location.split(",");
      return a[1] + ", " + a[2];
    },

    short_delivery_location: function () {
      let a = this.delivery_location.split(",");
      return a[1] + ", " + a[2];
    },
  },
};
</script>

<style scoped>
.trip-row {
  width: 100%;

  padding: 16px;

  position: relative;
  border-radius: 4px;
  border-bottom: 1px solid rgb(206, 206, 206);
  border-right: 1px solid rgb(206, 206, 206);
  border-left: 1px solid rgb(206, 206, 206);
}
td {
  width: fit-content;
  padding: 0 20px;
}
</style>
